import axios from "../../axios/AxiosConfig";
import { fork, put, all, takeLatest } from "redux-saga/effects";
import {
  CUSTOMER_IO_EVENT,
  CUSTOMER_IO_EVENT_ANONYMOUS,
  GET_BLOGS,
  GET_NOTIFICATIONS,
  GET_OFFERS,
  READ_ALL_NOTIFICATIONS,
} from "./actionTypes";
import {
  startLoading,
  stopLoading,
  getOffersSuccess,
  getOffersFailed,
  getBlogsSuccess,
  getBlogsFailed,
  getNotificationsSuccess,
  getNotificationsFailed,
  readAllNotificationsSuccess,
  readAllNotificationsFailed,
  getNotifications,
  customerIoEventSuccess,
  customerIoEventFailed,
} from "./actions";

//getAllOffers User Saga
function* getAllOffers() {
  try {
    yield put(startLoading());
    const response = yield axios.get("/offer");
    yield put(getOffersSuccess(response.data));
  } catch (error) {
    yield put(getOffersFailed(error.response));
  } finally {
    yield put(stopLoading());
  }
}

//getAllBlogs User Saga
function* getAllBlogs() {
  try {
    yield put(startLoading());
    const response = yield axios.get("/blog");
    yield put(getBlogsSuccess(response.data));
  } catch (error) {
    yield put(getBlogsFailed(error.response));
  } finally {
    yield put(stopLoading());
  }
}

//getAllNotifications User Saga
function* getAllNotifications() {
  try {
    yield put(startLoading());
    const response = yield axios.get("/users/notifications");
    yield put(getNotificationsSuccess(response.data));
  } catch (error) {
    yield put(getNotificationsFailed(error.response));
  } finally {
    yield put(stopLoading());
  }
}

//getReadAllNotifications User Saga
function* getReadAllNotifications() {
  try {
    yield put(startLoading());
    const response = yield axios.post("/users/notifications/read-all");
    yield put(readAllNotificationsSuccess(response.data));
    yield put(getNotifications());
  } catch (error) {
    yield put(readAllNotificationsFailed(error.response));
  } finally {
    yield put(stopLoading());
  }
}
//customerIOevent User Saga
function* customerIOevent({ payload, eventName }) {
  try {
    yield put(startLoading());
    const response = yield axios.post(`/customerio/${eventName}`, payload);
    yield put(customerIoEventSuccess(response.data));
  } catch (error) {
    yield put(customerIoEventFailed(error.response));
  } finally {
    yield put(stopLoading());
  }
}

//customerIOevent User Saga
function* customerIOeventAnonymous({ payload, eventName }) {
  try {
    yield put(startLoading());
    const response = yield axios.post(
      `/customerio/${eventName}/anonymous`,
      payload
    );
    yield put(customerIoEventSuccess(response.data));
  } catch (error) {
    yield put(customerIoEventFailed(error.response));
  } finally {
    yield put(stopLoading());
  }
}

//All Offers User Watcher
function* watchGetAllOffers() {
  yield takeLatest(GET_OFFERS, getAllOffers);
}

//All Blogs User Watcher
function* watchGetAllBlogs() {
  yield takeLatest(GET_BLOGS, getAllBlogs);
}

//All Notifications User Watcher
function* watchGetAllNotifications() {
  yield takeLatest(GET_NOTIFICATIONS, getAllNotifications);
}

//Read All Notifications User Watcher
function* watchGetReadAllNotifications() {
  yield takeLatest(READ_ALL_NOTIFICATIONS, getReadAllNotifications);
}

//Customer IO Event Watcher
function* watchCustomerIOevent() {
  yield takeLatest(CUSTOMER_IO_EVENT, customerIOevent);
}

//Customer IO Event Watcher
function* watchCustomerIOeventAnonymous() {
  yield takeLatest(CUSTOMER_IO_EVENT_ANONYMOUS, customerIOeventAnonymous);
}

export default function* dashboardSaga() {
  yield all([
    fork(watchGetAllOffers),
    fork(watchGetAllBlogs),
    fork(watchGetAllNotifications),
    fork(watchGetReadAllNotifications),
    fork(watchCustomerIOevent),
    fork(watchCustomerIOeventAnonymous),
  ]);
}
