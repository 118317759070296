import { Button, Link, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import AppointmentServices from "./AppointmentServices";
import DateTimePicker from "./DateTimePicker";
import AppointmentCars from "./AppointmentCars";
import { useForm } from "react-hook-form";
import { timeSlots } from "../../../data/data";
import { useDispatch, useSelector } from "react-redux";
import { appontment } from "../../../store/appointments/actions";
import { useLocation, useNavigate } from "react-router";
import Lottie from "react-lottie-player";
import { LoadingButton } from "@mui/lab";
import completeAppt from "../../../assets/images/completeAppt.json";
import NewCar from "../../../assets/images/new_car.svg";
import ReuseableModal from "../../reuseable/ReuseableModal";
import styled from "@emotion/styled";
import { getCars } from "../../../store/garage/actions";
import { customerIoEvent } from "../../../store/dashboard/actions";
import useBackButton from "../../reuseable/hooks/useBackButton";
import toast from "react-hot-toast";
import moment from "moment";
import { getUser } from "../../../store/auth/actions";
import {
  getCouponDetailAction,
  resetCouponDetailAction,
  resetErrorAction,
} from "../../../store/coupon/actions";
import { getDisabledDayIndexes } from "../../../utils/helper";

var index = 0;
const AppointmentComponent = () => {
  const { user } = useSelector((state) => state.auth);
  const CustomButton = styled(Button)(({ theme }) => ({
    "&:hover": {
      backgroundColor: "red", // your hover styles here
    },
  }));
  const [userAppointment, setUserAppointment] = useState(false);
  const handleUserAppointmentClose = () => {
    setUserAppointment(false);
    nav("/appointment/all-appointments");
  };

  const { openingHours } = user?.shopId;
  const disabledDayIndexes = getDisabledDayIndexes(openingHours);

  const getAdjustedDate = (date) => {
    let adjustedDate = moment(date); // Start with the provided date

    // Check if the current day is in emptyIndexes
    while (disabledDayIndexes.includes(adjustedDate.day())) {
      adjustedDate = adjustedDate.add(1, "day"); // Move to the next day
    }

    return adjustedDate.toDate(); // Return the first valid date
  };

  const [startDate, setStartDate] = useState(getAdjustedDate(new Date()));

  const [timeValue, setTimeValue] = useState(timeSlots[0]);

  const location = useLocation();
  const [outerTimeStamps, setOuterTimeStamps] = useState({
    isEarlyBird: false,
    isAfterHours: false,
  });

  const [serviceType, setServiceType] = useState();

  const [selectedCar, setSelectedCar] = useState();

  const dispatch = useDispatch();
  function convertTimeFormat(time) {
    if (!time) {
      toast.error("Please select a time");
    } else {
      const timeArr = time.split(":");
      const hours = parseInt(timeArr[0]);

      const minutes = timeArr[1];
      const period = timeArr[1]?.split(" ")[1];
      const periodValue = period.toUpperCase();

      let convertedHours = hours;
      if (periodValue === "PM" && hours !== 12) {
        convertedHours += 12;
      } else if (periodValue === "AM" && hours === 12) {
        convertedHours = 0;
      }

      return `${convertedHours}:${minutes}`;
    }
  }
  const { isLoading, appointment, recomended } = useSelector(
    (state) => state.appointments
  );

  const { carsList } = useSelector((state) => state.garage);

  useEffect(() => {
    dispatch(getCars());
    dispatch(getUser());
  }, []);

  const carImage = carsList?.filter((car) => car._id === selectedCar);
  const carBooked = carsList?.filter((car) => car._id === selectedCar);
  const {
    handleSubmit,
    reset,
    formState,
    register,
    setValue,
    watch,
    getValues,
    trigger,
  } = useForm({
    defaultValues: {
      pageName: "APPOINTMENT_TAB",
    },
  });
  const { errors } = formState;

  const onSubmit = (data) => {
    delete data?.coupon;
    const time = convertTimeFormat(timeValue);

    let convTime = new Date(startDate);
    convTime.setMinutes(0);
    convTime.setSeconds(0);
    convTime.setHours(time.split(" ")[0].split(":")[0]);
    let date = convTime;

    let payload = {};
    if (selectedCar) {
      payload = {
        appointmentAt: date && date,
        description: data.description,
        isEarlyBird: outerTimeStamps.isEarlyBird,
        isAfterHours: outerTimeStamps.isAfterHours,
        appointmentReason: data.appointmentReason,
        pageName: location?.state?.pageName
          ? location.state.pageName
          : "APPOINTMENT_TAB",
        vehicle: selectedCar,
      };
    } else {
      payload = {
        appointmentAt: date && date,
        description: data.description,
        isEarlyBird: outerTimeStamps.isEarlyBird,
        isAfterHours: outerTimeStamps.isAfterHours,
        appointmentReason: data.appointmentReason,
        pageName: location?.state?.pageName
          ? location.state.pageName
          : "APPOINTMENT_TAB",
      };
    }
    reset();
    dispatch(appontment(payload, setUserAppointment));
    if (location?.state?.pageName === "PLATINUM_TAB") {
      const eventName = "plat_mem_appt";
      const payload = {
        sharedTime: new Date(),
      };
      dispatch(customerIoEvent(payload, eventName));
    }
  };

  let reason;
  if (appointment) {
    reason = recomended.filter(
      (rec) => rec._id === appointment?.appointmentReason
    );
  }

  const dateConvertion = (newDate) => {
    const parts = newDate.split("-").map((part) => parseInt(part, 10));
    const date = new Date(parts[0], parts[1] - 1, parts[2]);

    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();

    const monthStr = new Date(year, month).toLocaleString("en-US", {
      month: "long",
    });

    return `${day} ${monthStr}, ${year}`;
  };

  // const convertTime = (time) => {
  //   var hours = parseInt(time.substring(0, 2));
  //   var minutes = time.substring(3, 5);
  //   var ampm = hours >= 12 ? "pm" : "am";
  //   hours = hours % 12;
  //   hours = hours ? hours : 12; // the hour '0' should be '12'
  //   var convertedTime = hours + ":" + minutes + " " + ampm;
  //   return convertedTime;
  // };

  const convertTime = (time) => {
    const localDatetimeString = new Date(time).toLocaleString();
    console.log(
      "🚀 ~ file: AppointmentComponent.jsx:169 ~ convertTime ~ date:",
      localDatetimeString
    );
    return localDatetimeString.split(",")[1].substr(0, 6);
  };

  const nav = useNavigate();
  useEffect(() => {
    window.addEventListener("popstate", handleBackButton);
    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, []);

  function handleBackButton(event) {
    console.log("iside handleBackButton");
    nav("/garage");
  }

  const redeenCode = () => {
    const code = getValues()?.coupon;
    if (!code) return;
    dispatch(getCouponDetailAction({ couponCode: code }));
  };
  const couponDetail = useSelector((state) => state.Coupon.couponDetail);
  const error = useSelector((state) => state.Coupon.error);

  useEffect(() => {
    if (couponDetail) {
      setValue(
        "description",
        `COUPON CODE IS ${couponDetail?.code}
DISCOUNT VALUE $${couponDetail?.reward}.00`
      );
    }
  }, [couponDetail]);

  useEffect(() => {
    dispatch(resetCouponDetailAction());
    setValue("description", null);
  }, []);

  useEffect(() => {
    if (!couponDetail && error) {
      dispatch(resetCouponDetailAction());
      setValue("description", null);
    }
    setTimeout(() => {
      dispatch(resetErrorAction());
    }, 5000);
  }, [error]);

  useEffect(() => {
    dispatch(resetErrorAction());
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack
          padding={"3em 2em"}
          spacing={4}
          alignItems={"center"}
          width={"100%"}
        >
          <Stack spacing={4} width={"100%"}>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography variant="h4" color={"#344054"}>
                Book Appointment
              </Typography>
              <Link
                onClick={() => nav("/appointment/all-appointments")}
                underline="hover"
                alignSelf={"center"}
                sx={{ color: "#F83D4B" }}
              >
                All Appointments
              </Link>
            </Stack>
            <AppointmentCars
              index={index + 1}
              setSelectedCar={setSelectedCar}
              selectedCar={selectedCar}
            />
            <DateTimePicker
              disabledDayIndexes={disabledDayIndexes}
              startDate={startDate}
              setStartDate={setStartDate}
              timeValue={timeValue}
              setTimeValue={setTimeValue}
              outerTimeStamps={outerTimeStamps}
              setOuterTimeStamps={setOuterTimeStamps}
              index={index + 2}
            />
            <AppointmentServices
              serviceType={serviceType}
              setServiceType={setServiceType}
              register={register}
              setValue={setValue}
              watch={watch}
              error={errors}
            />
            <Typography fontWeight={"bold"}>
              3. Do you have coupon? Enter here
            </Typography>
            <Stack direction={"row"} gap={"12px"} alignItems={"center"}>
              <TextField
                id="outlined-coupon"
                label="Enter coupon if you have"
                rows={1}
                sx={{
                  bgcolor: "#ffffff",
                  width: "40%",
                  display: "inline-flex",
                }}
                {...register("coupon")}
                error={error}
              />
              <Button
                variant="contained"
                type="button"
                size="large"
                sx={{
                  borderRadius: "54.6591px",
                  background:
                    "linear-gradient(80.67deg, #C42E66 27.97%, #D23852 96.9%)",
                  fontSize: "small",
                  p: 2,
                  width: "25%",
                  display: "inline-flex",
                }}
                onClick={() => redeenCode()}
              >
                Redeem Code
              </Button>
            </Stack>
          </Stack>
          <TextField
            id="outlined-optional"
            label="Anything else we should know? (optional)"
            multiline
            rows={4}
            InputLabelProps={{ shrink: true }}
            sx={{ bgcolor: "#ffffff" }}
            {...register("description")}
            fullWidth
          />
          <LoadingButton
            loading={isLoading}
            variant="contained"
            type="submit"
            size="large"
            sx={{
              borderRadius: "54.6591px",
              background:
                "linear-gradient(80.67deg, #C42E66 27.97%, #D23852 96.9%)",
              fontSize: "small",
              p: 2,
              width: "100%",
            }}
          >
            Complete Booking
          </LoadingButton>
        </Stack>
      </form>
      {/* Appintment Created  */}
      {appointment && (
        <ReuseableModal
          handleClose={handleUserAppointmentClose}
          open={userAppointment}
          width={"40%"}
        >
          <Stack mt={"-50px"}>
            <Lottie
              loop
              animationData={completeAppt}
              play
              style={{ width: "100%", height: 200 }}
            />
          </Stack>
          <Typography
            variant="h6"
            color={"#C42E66"}
            sx={{ marginTop: "-2em !important" }}
          >
            Congratulations
          </Typography>
          <Typography variant="h6" color={"#000000"} mt={"0px !important"}>
            Your Appointment has been booked
          </Typography>
          <Typography variant="subtitle2">{`We look forward to the privilege of taking care of your ${user?.shopId?.address?.city},${user?.shopId?.address?.state}`}</Typography>
          <Stack
            direction={{ xs: "row", sm: "column", md: "row" }}
            alignItems={{ xs: "start", sm: "center", md: "start" }}
            justifyContent={"space-between"}
            width={"100%"}
            borderTop={"1px solid gray"}
            paddingY={1}
            borderBottom={"1px solid gray"}
          >
            <Typography variant="subtitle2">Appointment Date</Typography>
            <Typography variant="subtitle2">
              {`${moment(appointment?.appointmentAt).format("MMMM Do YYYY")}`}
            </Typography>
          </Stack>
          <Stack
            direction={{ xs: "row", sm: "column", md: "row" }}
            alignItems={{ xs: "start", sm: "center", md: "start" }}
            justifyContent={"space-between"}
            width={"100%"}
            mt="0px !important"
            paddingY={1}
            borderBottom={"1px solid gray"}
          >
            <Typography variant="subtitle2">Appointment Time</Typography>

            <Typography variant="subtitle2">
              {appointment?.isEarlyBird
                ? "Early Bird"
                : appointment?.isAfterHours
                ? "After hours drop off"
                : `${moment(appointment?.appointmentAt).format("hh:mm a")}`}
            </Typography>
          </Stack>
          <Typography alignSelf={"start"} fontWeight={"bold"}>
            Summary
          </Typography>
          <Stack
            direction={{ xs: "row", sm: "column", md: "row" }}
            width={"100%"}
            spacing={2}
          >
            <Stack
              borderRadius={"20px"}
              width={{ xs: "40%", sm: "100%", md: "40%" }}
            >
              <img
                src={carImage[0]?.imageURL || "https://res.cloudinary.com/dd6hviyo3/image/upload/v1727790135/umyzzsaellpm7bztehcl.svg"}
                alt=""
                height={"100%"}
                style={{ objectFit: "contain" }}
              />
            </Stack>
            <Stack spacing={1} flexGrow={1}>
              <Typography fontWeight={"bold"}>
                {carBooked.length > 0
                  ? `${carBooked[0]?.tekmetricRaw?.year} ${carBooked[0]?.tekmetricRaw?.make} ${carBooked[0]?.tekmetricRaw?.model}`
                  : "New Car"}
              </Typography>
              <Typography color={"#DFB11E"}>{reason[0]?.title}</Typography>
              <Typography
                variant="caption"
                color="#20282B"
                noWrap
                sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
                width={{ xs: "30%", sm: "60%", md: "30%" }}
                height={"20px !important"}
              >
                {appointment?.description}
              </Typography>
            </Stack>
          </Stack>
          <Stack
            direction={{ xs: "row", sm: "column", md: "row" }}
            alignItems={{ xs: "start", sm: "center", md: "start" }}
            justifyContent={"space-between"}
            width={"100%"}
            borderTop={"1px solid gray"}
            paddingY={1}
            borderBottom={"1px solid gray"}
          >
            <Typography variant="subtitle2" color={"#C42E66"}>
              Reward of Completion of Service
            </Typography>
            <Typography variant="subtitle2" color={"#787B82"}>
              {`$${appointment?.rewardValue}`}
            </Typography>
          </Stack>
          <CustomButton
            sx={{
              borderRadius: "54.6591px",
              bgcolor: "#F83D4B",
              fontSize: "small",
              p: 2,
              color: "white",
              width: "100%",
            }}
            onClick={() => handleUserAppointmentClose()}
          >
            Continue
          </CustomButton>
        </ReuseableModal>
      )}
    </>
  );
};

export default AppointmentComponent;
