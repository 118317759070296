import { Stack } from '@mui/material'
import React from 'react'
import AllOffers from '../../../components/profile/dashboard/AllOffers'

const AllOffersScreen = () => {
    return (
        <Stack width={{ xs: "100vw", md: "75vw" }}>
            <AllOffers />
        </Stack>
    )
}

export default AllOffersScreen