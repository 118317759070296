import { LoadingButton } from "@mui/lab";
import { TextField, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { signupUser } from "../../../store/auth/actions";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useEffect } from "react";

const SignupForm = () => {
  let Guest = sessionStorage.getItem("guestInfo");
  let refCod = sessionStorage.getItem("referral");

  const schema = yup.object({
    fullName: yup.string().required("Required"),
    email: yup.string().required("Required"),
    referralCode: Guest ? yup.string().required("Required") : yup.string(),
  });

  const { isLoading, user } = useSelector((state) => state.auth);
  const { handleSubmit, register, formState, control, reset } = useForm({
    resolver: yupResolver(schema),
  });
  const { errors } = formState;
  console.log("🚀 ~ file: SignupForm.jsx:27 ~ SignupForm ~ errors:", errors);
  const nav = useNavigate();
  const dispatch = useDispatch();

  const onSubmit = (data) => {
    var payload = {
      fullName: data.fullName,
      email: data.email,
      deviceType: "web",
    };
    if (data.referralCode !== "") payload["referralCode"] = data.referralCode;
    reset();
    dispatch(signupUser(payload, nav));
  };
  useEffect(() => {
    if (Guest) {
      Guest = JSON.parse(Guest);
      let obj = {
        fullName: Guest?.guestUserInfo?.fullName,
        email: Guest?.guestUserInfo?.email,
      };
      if (refCod) {
        obj["referralCode"] = refCod;
      }
      reset(obj);
    } else if (user) {
      let obj = {
        fullName: user?.fullName,
        email: user?.email,
      };
      if (refCod) {
        obj["referralCode"] = refCod;
      }
      reset(obj);
    }
  }, []);

  return (
    <>
      <Typography variant="h4" fontWeight={"bold"} lineHeight={"1.3"}>
        Welcome to Integrity 1st Automotive!
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack color={"#848FAC"} spacing={2}>
          <Typography alignSelf={"start"}>
            We need a little more information!
          </Typography>
          <TextField
            className="TextField-without-border-radius"
            type={"text"}
            label="Full Name"
            control={control}
            name="fullName"
            {...register("fullName", { required: true })}
            error={errors.fullName}
          />
          {errors?.fullName?.message && (
            <Typography
              mt={"0px !important"}
              color={"#D53939"}
              variant={"caption"}
            >
              {errors?.fullName?.message}
            </Typography>
          )}
          <TextField
            className="TextField-without-border-radius"
            type={"email"}
            name={"email"}
            label="Email Address"
            control={control}
            {...register("email", { required: true })}
            error={errors.email}
          />
          {errors?.email?.message && (
            <Typography
              mt={"0px !important"}
              color={"#D53939"}
              variant={"caption"}
            >
              {errors?.email?.message}
            </Typography>
          )}
          <TextField
            className="TextField-without-border-radius"
            type={"text"}
            name={"referralCode"}
            label="Enter Referral/Coupon Code (Optional)"
            error={errors.referralCode}
            {...register("referralCode", { required: true })}
          />
          {errors?.referralCode?.message && (
            <Typography
              mt={"0px !important"}
              color={"#D53939"}
              variant={"caption"}
            >
              {errors?.referralCode?.message}
            </Typography>
          )}
          <LoadingButton
            loading={isLoading}
            variant="contained"
            type="submit"
            size="large"
            sx={{
              borderRadius: "54.6591px",
              bgcolor: "#F83D4B",
              fontSize: "small",
              p: 2,
              width: "100%",
            }}
          >
            Complete Setup
          </LoadingButton>
        </Stack>
      </form>
    </>
  );
};

export default SignupForm;
