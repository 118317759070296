import  React,{ useState,useEffect } from "react";
import "../../../utils/css/styles.css";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useController } from "react-hook-form";

const StoreLocation = ({ shops, control, name, rules, errors, label }) => {
  const { field } = useController({
    name,
    rules,
    control, 
  });
  const [fieldValue,setFieldValue] = useState("")
  useEffect(()=>{
    setFieldValue(field.value)
  },[fieldValue])
  
  return (
    <FormControl fullWidth>
      <InputLabel id="store-label">{label}</InputLabel>
      <Select
        labelId="store-label"
        {...field}
        id="store-label-id"
        sx={{
          width: "100%",
          borderRadius: "54.6591px !important",
        }}
        label={label}
        error={errors?.shopId}
        value={fieldValue}
        onChange={(event)=>{
          field.onChange(event.target.value);
          setFieldValue(event.target.value)
        }}
      >
        {shops &&
          shops.map((address, index) => {
            return (
              <MenuItem value={address._id} key={index}>
                {address.address.fullAddress}
              </MenuItem>
            );
          })}
      </Select>
      {errors?.shopId?.message && (
        <Typography mt={"0px !important"} color={"#D53939"} variant={"caption"}>
          {errors?.shopId?.message}
        </Typography>
      )}
    </FormControl>
  );
};

export default StoreLocation;
