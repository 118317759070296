import {
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAppontment } from "../../../store/appointments/actions";
import { getCars } from "../../../store/garage/actions";
import AppointmentHistoryCard from "../../reuseable/cards/Appointment-HistoryCard";
import NewCar from "../../../assets/images/new_car.svg";
import { useLocation, useNavigate } from "react-router-dom";
import useBackButton from "../../reuseable/hooks/useBackButton";
import NotFound from "../../../assets/images/NotFound.svg";

const AllAppointmentsComponent = () => {
  const dispatch = useDispatch();
  const { appointments } = useSelector((state) => state.appointments);

  const nav = useNavigate();
  useEffect(() => {
    dispatch(getAppontment());
  }, []);
  const location = useLocation();

  const getAppointmentTime = (timestamp) => {
    const localDatetimeString = new Date(timestamp).toLocaleString();
    console.log(
      "🚀 ~ file: AppointmentComponent.jsx:169 ~ convertTime ~ date:",
      localDatetimeString
    );
    return localDatetimeString.split(",")[1];
  };
  const getAppointmentDate = (timestamp) => {
    timestamp = timestamp.split("T");

    return timestamp[0];
  };
  return (
    <>
      <Stack
        padding={"3em 2em"}
        spacing={4}
        sx={{
          height: "100%",
        }}
      >
        <Typography variant="h4" color={"#344054"}>
          All Appointments
        </Typography>
        <Grid container xs={12}>
          {appointments?.appointments.length > 0 ? (
            appointments.appointments.map((appointment) => (
              <Grid item xs={12} md={6} xl={4}>
                <AppointmentHistoryCard
                  title={appointment?.vehicle?.name || "New Vehicle"}
                  appointmentReason={appointment.appointmentReason.title}
                  appointmentDate={getAppointmentDate(
                    appointment.appointmentAt
                  )}
                  appointmentTime={getAppointmentTime(
                    appointment.appointmentAt
                  )}
                  bgImage={appointment?.vehicle?.imageURL || "https://res.cloudinary.com/dd6hviyo3/image/upload/v1727790135/umyzzsaellpm7bztehcl.svg"}
                  isEarly={appointment.isEarlyBird}
                  isAfter={appointment.isAfterHours}
                />
              </Grid>
            ))
          ) : (
            <Grid
              item
              xs={12}
              display={"flex"}
              flexDirection={"column"}
              justifyContent="center"
              alignItems="center"
              fontWeight={"bold"}
            >
              <img src={NotFound} alt="" />
              NO RESULT FOUND
            </Grid>
          )}
        </Grid>
      </Stack>
    </>
  );
};

export default AllAppointmentsComponent;
