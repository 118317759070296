import axios from "../../axios/AxiosConfig";
import { fork, put, all, takeLatest } from "redux-saga/effects";
import {
  CLEAR_DATA,
  GET_DYNAMIC_KEYS,
  LOGIN,
  REGISTER,
  CHANGE_PASSWORD,
  RESET_PASSWORD,
  RESET_PASSWORD_OTP,
  SHOPS,
  SIGNUP,
  USER,
  VERIFY_PHONE,
  DELETE_USER,
  GET_USER,
} from "./actionTypes";
import {
  stopLoading,
  loginSuccess,
  registerSuccess,
  startLoading,
  getShopsSuccess,
  loginFailed,
  verifyPhoneSuccess,
  verifyPhoneFailed,
  clearDataSuccess,
  registerFailed,
  signupUserFailed,
  signupUserSuccess,
  resetPasswordSuccess,
  resetPasswordFailed,
  getDynamicKeysSuccess,
  getDynamicKeysFailed,
  userSuccess,
  userFailed,
  resetPasswordOTPSuccess,
  resetPasswordOTPFailed,
  changePasswordSuccess,
  changePasswordFailed,
  deleteUserSuccess,
  deleteUserFailed,
  userFlush,
  getUserSuccess,
  getUserFailed,
} from "./actions";
import { customerIoEvent } from "../dashboard/actions";
import { toast } from "react-hot-toast";
import { store } from "../index";

//Clear Data Saga
function* clearErrorData({ payload }) {
  try {
    yield put(startLoading());
    yield put(clearDataSuccess(payload));
  } catch (error) {
  } finally {
    yield put(stopLoading());
  }
}

//Login User Saga

function* login({ payload, nav }) {
  try {
    yield put(startLoading());
    const response = yield axios.post("/auth/login", payload);
    if (
      response?.data?.user?.fullName === "" ||
      response?.data?.user?.email === ""
    ) {
      nav("/signup");
      sessionStorage.setItem("signupToken", response?.data?.token);
      sessionStorage.setItem("initUser", JSON.stringify(response?.data?.user));
      toast.error("Please Complete Profile to get full access");
    } else {
      nav("/");
      yield put(loginSuccess(response.data));
    }
  } catch (error) {
    yield put(loginFailed(error.response));
  } finally {
    yield put(stopLoading());
  }
}

//Register User Saga

function* register({ payload, nav }) {
  try {
    yield put(startLoading());
    const response = yield axios.post("/auth/send-phonecode", payload);
    nav("/verify");
    yield put(registerSuccess(response.data));
  } catch (error) {
    yield put(registerFailed(error.response));
  } finally {
    yield put(stopLoading());
  }
}

//Verify User Saga

function* vreifyPhone({ payload, nav }) {
  try {
    yield put(startLoading());
    const response = yield axios.post("/auth/verify-phonecode", payload);
    nav("/signup");
    yield put(verifyPhoneSuccess(response.data));
  } catch (error) {
    yield put(verifyPhoneFailed(error.response));
  } finally {
    yield put(stopLoading());
  }
}

//Signup User Saga
function* signUp({ payload, nav }) {
  const token = store.getState().auth.token
    ? store.getState().auth.token
    : sessionStorage.getItem("signupToken");
  try {
    yield put(startLoading());
    const response = yield axios.post("/users/post-register", payload, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    sessionStorage.removeItem("signupToken");
    sessionStorage.removeItem("initUser");
    localStorage.setItem("token", token);
    nav("/");
    yield put(signupUserSuccess(response.data));
  } catch (error) {
    yield put(signupUserFailed(error.response));
  } finally {
    yield put(stopLoading());
  }
}

//Change Password Saga

function* changePassword({ payload, nav }) {
  try {
    yield put(startLoading());
    const response = yield axios.post("/auth/change-password", payload);
    nav("/account");
    yield put(changePasswordSuccess(response.data));
  } catch (error) {
    yield put(changePasswordFailed(error.response));
  } finally {
    yield put(stopLoading());
  }
}

//Reset Password Saga

function* resetUserPassword({ payload, nav }) {
  try {
    yield put(startLoading());
    const response = yield axios.post("/auth/send-forgot-phonecode", payload);
    nav("/password/otp");
    yield put(resetPasswordSuccess(response.data, payload));
  } catch (error) {
    yield put(resetPasswordFailed(error.response));
  } finally {
    yield put(stopLoading());
  }
}

//Reset Password OTP Saga

function* resetUserPasswordOTP({ payload, nav }) {
  try {
    yield put(startLoading());
    const response = yield axios.post("/auth/verify-forgot-phonecode", payload);
    nav("/password/recover");
    yield put(resetPasswordOTPSuccess(response.data, payload));
  } catch (error) {
    yield put(resetPasswordOTPFailed(error.response));
  } finally {
    yield put(stopLoading());
  }
}
//patchUser User Saga
function* patchUser({ payload, nav }) {
  try {
    yield put(startLoading());
    const response = yield axios.patch("/users", payload);
    yield put(userSuccess(response.data));
    const eventName = "forgot_password_success";
    const phone = sessionStorage.getItem("forgotPhone");
    const eventPayload = {
      PhoneNumber: phone,
      sharedTime: new Date(),
    };
    yield put(customerIoEvent(eventPayload, eventName));
    sessionStorage.removeItem("forgotPhone");
    nav("/login");
  } catch (error) {
    yield put(userFailed(error.response));
  } finally {
    yield put(stopLoading());
  }
}

//getUser User Saga
function* getCurrentUser() {
  try {
    yield put(startLoading());
    const response = yield axios.get("/users/me");
    yield put(getUserSuccess(response.data));
  } catch (error) {
    yield put(getUserFailed(error.response?.data?.message));
  } finally {
    yield put(stopLoading());
  }
}

//Get All Stores Saga

function* getStores({ payload }) {
  try {
    yield put(startLoading());
    const response = yield axios.get("/shops");
    yield put(getShopsSuccess(response.data));
  } catch (error) {
    // yield sagaErrorHandler(error.response);
  } finally {
    yield put(stopLoading());
  }
}

//getAllDynamicKeys User Saga
function* getAllDynamicKeys() {
  try {
    yield put(startLoading());
    const response = yield axios.get("/dynamic-keys");
    yield put(getDynamicKeysSuccess(response.data));
  } catch (error) {
    yield put(getDynamicKeysFailed(error.response));
  } finally {
    yield put(stopLoading());
  }
}

function* deleteloggedInUser({ nav }) {
  try {
    yield put(startLoading());
    const response = yield axios.delete("/auth");
    yield put(userFlush());
    window.localStorage.clear();
    sessionStorage.clear();
    nav("/login");
    yield put(deleteUserSuccess(response.data));
  } catch (error) {
    yield put(deleteUserFailed(error.response));
  } finally {
    yield put(stopLoading());
  }
}

//

//patchUser Watcher
function* watchPatchUser() {
  yield takeLatest(USER, patchUser);
}

//patchUser Watcher
function* watchGetUser() {
  yield takeLatest(GET_USER, getCurrentUser);
}

//Clear Data Watcher
function* watchClearData() {
  yield takeLatest(CLEAR_DATA, clearErrorData);
}

//Login User Watcher

function* watchLogin() {
  yield takeLatest(LOGIN, login);
}

//Register User Watcher

function* watchRegister() {
  yield takeLatest(REGISTER, register);
}

//Verify User Watcher

function* watchVrifyPhone() {
  yield takeLatest(VERIFY_PHONE, vreifyPhone);
}

//Signup User Watcher

function* watchSignupUser() {
  yield takeLatest(SIGNUP, signUp);
}

//Change Password Watcher

function* watchChangePassword() {
  yield takeLatest(CHANGE_PASSWORD, changePassword);
}

//reset password Watcher

function* watchResetPassword() {
  yield takeLatest(RESET_PASSWORD, resetUserPassword);
}

//reset password otp User Watcher

function* watchResetPasswordOTP() {
  yield takeLatest(RESET_PASSWORD_OTP, resetUserPasswordOTP);
}

//Get Shops Watcher

function* watchGetShops() {
  yield takeLatest(SHOPS, getStores);
}

//All Dynamic Keys User Watcher
function* watchGetAllDynamicKeys() {
  yield takeLatest(GET_DYNAMIC_KEYS, getAllDynamicKeys);
}

//Delete User Watcher
function* watchDeleteUser() {
  yield takeLatest(DELETE_USER, deleteloggedInUser);
}

export default function* authSaga() {
  yield all([
    fork(watchRegister),
    fork(watchVrifyPhone),
    fork(watchGetShops),
    fork(watchLogin),
    fork(watchClearData),
    fork(watchSignupUser),
    fork(watchChangePassword),
    fork(watchResetPassword),
    fork(watchResetPasswordOTP),
    fork(watchGetAllDynamicKeys),
    fork(watchPatchUser),
    fork(watchGetUser),
    fork(watchDeleteUser),
  ]);
}
