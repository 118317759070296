import React from 'react'
import VerifyPhone from '../../components/auth/register/verifyPhone/VerifyPhoneComponent'

const VerifyPhoneScreen = () => {
    return (
        <>
            <VerifyPhone />
        </>
    )
}

export default VerifyPhoneScreen