import produce from "immer";
import { toast } from "react-hot-toast";
import {
  GET_COUPON_DETAIL_FAILED,
  GET_COUPON_DETAIL_SUCCESS,
  RESET_COUPON_DETAIL,
  RESET_ERROR
} from "./actiontypes";

const initialState = {
  couponDetail: null,
  error: null,
};

const Coupon = produce((state, action) => {
  switch (action.type) {
    case GET_COUPON_DETAIL_SUCCESS:
      // eslint-disable-next-line no-unused-expressions
      action.payload?.userId ? toast.success(action.payload.message) : null;
      return {
        ...state,
        couponDetail: action.payload.data,
        error: action.payload.error,
      };
    case RESET_COUPON_DETAIL:
      return {
        ...state,
        couponDetail: null,
      };
    case RESET_ERROR:
      return {
        ...state,
        error: null,
      };

    case GET_COUPON_DETAIL_FAILED:
      // eslint-disable-next-line no-unused-expressions
      action.payload?.userId ? toast.error(action.payload.message) : null;
      return {
        ...state,
        couponDetail: action.payload.data,
        error: action.payload.error,
      };
    default:
      break;
  }
}, initialState);

export default Coupon;
