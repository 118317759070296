import { Stack } from '@mui/material'
import React from 'react'
import ServiceHistoryComponent from '../../../components/profile/garage/ServiceHistoryComponent'

const ServiceHistoryScreen = () => {
    return (
        <Stack width={{ xs: "100vw", md: "75vw" }}>
            <ServiceHistoryComponent />
        </Stack>
    )
}

export default ServiceHistoryScreen