import {
  GET_COUPON_DETAIL,
  GET_COUPON_DETAIL_FAILED,
  GET_COUPON_DETAIL_SUCCESS,
  RESET_COUPON_DETAIL,
  RESET_ERROR,
} from "./actiontypes";

export const getCouponDetailAction = (data) => {
  return {
    type: GET_COUPON_DETAIL,
    payload: data,
  };
};
export const resetCouponDetailAction = (data) => {
  return {
    type: RESET_COUPON_DETAIL,
    payload: data,
  };
};
export const getCouponDetailSuccessAction = (data) => {
  return {
    type: GET_COUPON_DETAIL_SUCCESS,
    payload: data,
  };
};
export const getCouponDetailFailedAction = (data) => {
  return {
    type: GET_COUPON_DETAIL_FAILED,
    payload: data,
  };
};
export const resetErrorAction = () => {
  return {
    type: RESET_ERROR,
  };
};
