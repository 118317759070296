import axios from "../../axios/AxiosConfig";
import { fork, put, all, takeLatest } from "redux-saga/effects";
import {
  startLoading,
  stopLoading,
  getAppontmentRecSuccess,
  getAppontmentRecFailed,
  appontmentSuccess,
  appontmentFailed,
  getAppontmentSuccess,
  getAppontmentFailed,
  guestAppontmentFailed,
  guestAppontmentSuccess,
} from "./actions";
import {
  APPOINTMENT,
  GET_APPOINTMENT,
  GET_APPOINTMENT_RECOMENDATIONS,
  GUEST_APPOINTMENT,
} from "./actionTypes";
import { toast } from "react-hot-toast";
import { customerIoEventAnonymous } from "../dashboard/actions";

//getAllRecomendations User Saga
function* getAllAppointmentRec() {
  try {
    yield put(startLoading());
    const response = yield axios.get("/appointment-reason");
    yield put(getAppontmentRecSuccess(response.data));
  } catch (error) {
    yield put(getAppontmentRecFailed(error.response));
  } finally {
    yield put(stopLoading());
  }
}

//getAllAppointments User Saga
function* getAllAppointment() {
  try {
    yield put(startLoading());
    const response = yield axios.get("/appointment");

    yield put(getAppontmentSuccess(response.data));
  } catch (error) {
    yield put(getAppontmentFailed(error.response));
  } finally {
    yield put(stopLoading());
  }
}

//getAllRecomendations User Saga
function* appointments({ payload, setUserAppointment }) {
  try {
    yield put(startLoading());
    const response = yield axios.post("/appointment", payload);
    toast.success("Appointment Created");
    setUserAppointment(true);
    yield put(appontmentSuccess(response.data));
  } catch (error) {
    toast.error(error.response.message);
    yield put(appontmentFailed(error.response));
  } finally {
    yield put(stopLoading());
  }
}

//guestAppointments User Saga
function* guestAppointments({ payload, setRegisterGuest, reff }) {
  try {
    yield put(startLoading());
    const response = yield axios.post("/appointment/guest", payload);
    toast.success("Appointment Created");
    setRegisterGuest(true);
    sessionStorage.setItem("referral", reff);
    yield put(guestAppontmentSuccess(response.data));
    // const {data:{guestUserInfo:{userTekmetricId}}} = response;
    // const eventName = "refferal_appointment_set";
    // const payloadAnonymous = {
    //   PhoneNumber: payload?.phoneNumber,
    //   sharedTime: new Date(),
    //   deviceType: "web",
    //   tekmetricId:userTekmetricId
    // };
    // yield put(customerIoEventAnonymous(payloadAnonymous, eventName));
  } catch (error) {
    toast.error(error.response.data.message);
    yield put(guestAppontmentFailed(error.response));
  } finally {
    yield put(stopLoading());
  }
}

//All Recomendations User Watcher
function* watchGetAppointmentRec() {
  yield takeLatest(GET_APPOINTMENT_RECOMENDATIONS, getAllAppointmentRec);
}

//All Appointments User Watcher
function* watchGetAppointment() {
  yield takeLatest(GET_APPOINTMENT, getAllAppointment);
}

//Create Appointment Watcher
function* watchPostAppointment() {
  yield takeLatest(APPOINTMENT, appointments);
}

//Create guestAppointment Watcher
function* watchPostGuestAppointment() {
  yield takeLatest(GUEST_APPOINTMENT, guestAppointments);
}

export default function* appointmentSaga() {
  yield all([
    fork(watchGetAppointmentRec),
    fork(watchPostAppointment),
    fork(watchGetAppointment),
    fork(watchPostGuestAppointment),
  ]);
}
