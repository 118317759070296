import {
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useMemo, useRef, useState } from "react";
import LeftSection from "../../LeftSection";
import RightSection from "../../RightSection";
import { useNavigate } from "react-router";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { RedSolidButton } from "../../../reuseable/button/Buttons";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import { userUser } from "../../../../store/auth/actions";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup.object({
  password: yup
    .string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
  conformPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Required"),
});
const RecoverPasswordComponent = () => {
  const { isLoading, keys } = useSelector((state) => state.auth);

  const nav = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const dispatch = useDispatch();
  const onSubmit = (data) => {
    const payload = {
      password: data.password,
    };
    reset();
    dispatch(userUser(payload, nav));
  };

  return (
    <>
      <Grid container spacing={1} height={"100%"}>
        <Grid item xs="6" display={{ xs: "none", md: "flex" }}>
          <LeftSection />
        </Grid>
        <Grid item xs="12" md="6" display={"flex"} justifyContent={"center"}>
          <RightSection>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Typography variant="h4" fontWeight={"bold"} lineHeight={"1.3"}>
                Create your New Password
              </Typography>
              <Stack color={"#848FAC"} spacing={2}>
                <Typography>Sign in your account</Typography>
                <TextField
                  className="TextField-without-border-radius"
                  type={showPassword ? "text" : "password"}
                  label="Create Password"
                  name="password"
                  error={errors?.password}
                  {...register("password", { required: true, minLength: 6 })}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {errors?.password?.message && (
                  <Typography
                    mt={"0px !important"}
                    color={"#D53939"}
                    variant={"caption"}
                  >
                    {errors?.password?.message}
                  </Typography>
                )}
                <TextField
                  className="TextField-without-border-radius"
                  type={showPassword ? "text" : "password"}
                  label="Confirm Password"
                  name="conformPassword"
                  error={errors?.conformPassword}
                  {...register("conformPassword", {
                    required: true,
                    minLength: 6,
                  })}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {errors?.conformPassword?.message && (
                  <Typography
                    mt={"0px !important"}
                    color={"#D53939"}
                    variant={"caption"}
                  >
                    {errors?.conformPassword?.message}
                  </Typography>
                )}
                <LoadingButton
                  loading={isLoading}
                  variant="contained"
                  type="submit"
                  size="large"
                  sx={{
                    borderRadius: "54.6591px",
                    bgcolor: "#F83D4B",
                    fontSize: "small",
                    p: 2,
                    width: "100%",
                  }}
                >
                  Complete Setup
                </LoadingButton>
              </Stack>
            </form>
          </RightSection>
        </Grid>
      </Grid>
    </>
  );
};

export default RecoverPasswordComponent;
