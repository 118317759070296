import { LoadingButton } from "@mui/lab";
import { Box, FormHelperText, Link, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { getToken } from "firebase/messaging";
import { MuiOtpInput } from "mui-one-time-password-input";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {
  getTokenFunc,
  messaging,
  onMessageListener,
} from "../../../../Firebase";
import { verifyPhone } from "../../../../store/auth/actions";

const VerifyForm = () => {
  const { isLoading } = useSelector((state) => state.auth);
  const phoneNumber = sessionStorage.getItem("phoneNumber");
  const token = sessionStorage.getItem("fcmToken");
  const nav = useNavigate();
  const handleNav = () => {
    nav("/register");
  };
  const dispatch = useDispatch();
  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      otp: "",
    },
  });
  const onSubmit = (data) => {
    const payload = {
      phoneNumber: phoneNumber,
      code: data.otp,
      deviceType: "web",
    };
    if (token) {
      payload["tokenData"] = {
        fcmToken: token,
        osName: "WEB",
      };
    }
    reset();
    dispatch(verifyPhone(payload, nav));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <Typography variant="h4" fontWeight={"bold"} lineHeight={"1.3"}>
          Enter 6-digit code we sent to your Phone Number
        </Typography>
        <Controller
          name="otp"
          control={control}
          rules={{ validate: (value) => value.length === 6 }}
          render={({ field, fieldState }) => (
            <Box>
              <MuiOtpInput sx={{ gap: 1 }} {...field} length={6} />
              {fieldState.invalid ? (
                <FormHelperText error>OTP invalid</FormHelperText>
              ) : null}
            </Box>
          )}
        />
        <LoadingButton
          loading={isLoading}
          variant="contained"
          type="submit"
          size="large"
          sx={{
            borderRadius: "54.6591px",
            bgcolor: "#F83D4B",
            fontSize: "small",
            p: 2,
            width: "100%",
          }}
        >
          Continue
        </LoadingButton>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          paddingTop={4}
          paddingX="1em"
        >
          <Typography variant="caption">
            Didn't get the code?{" "}
            <Link
              onClick={() => handleNav()}
              color={"#F83D4B"}
              underline="none"
            >
              Send Again
            </Link>
          </Typography>
          <Typography
            variant="caption"
            sx={{ cursor: "pointer" }}
            onClick={() => handleNav()}
          >
            Change Phone Number?
          </Typography>
        </Stack>
      </Stack>
    </form>
  );
};

export default VerifyForm;
