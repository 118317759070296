import React from 'react'
import ForgotPassword from '../../components/auth/forgotPassword/ForgotPasswordComponent'

const ForgotPasswordScreen = () => {
    return (
        <>
            <ForgotPassword />
        </>
    )
}

export default ForgotPasswordScreen