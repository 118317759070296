import { TextField, Typography } from "@mui/material";
import React from "react";

const GuestNameField = ({ fullName, setFullName, register, error }) => {
  const handleChange = (event) => {
    setFullName(event.target.value);
  };
  return (
    <>
      <Typography fontWeight={"bold"} sx={{ width: "100%" }}>
        5. What is your full name?
      </Typography>
      <TextField
        error={error && error.fullName}
        sx={{ bgcolor: "white", marginTop: "1em !important" }}
        id="full-name"
        label="Please enter your full name"
        variant="outlined"
        value={fullName}
        {...register("fullName", { required: true })}
        onChange={handleChange}
        fullWidth
      />
    </>
  );
};

export default GuestNameField;
