import React from 'react'
import RegisterComponent from '../../components/auth/register/RegisterComponent'

const RegistrationScreen = () => {
    return (
        <>
            <RegisterComponent />
        </>
    )
}

export default RegistrationScreen