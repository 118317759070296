import {
  createTheme,
  responsiveFontSizes,
  Stack,
  ThemeProvider,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import GarageCarCard from "../../reuseable/cards/GarageCarCard";
import NotFound from "../../../assets/images/NotFound.svg";
import loadingwheel from "../../../assets/images/loading-wheel.json";
import { useDispatch, useSelector } from "react-redux";
import { getCars } from "../../../store/garage/actions";
import Lottie from "react-lottie-player";

const GarageComponent = () => {
  const dispatch = useDispatch();
  const { carsList, isLoading } = useSelector((state) => state.garage);

  useEffect(() => {
    dispatch(getCars());
    // eslint-disable-next-line
  }, []);

  let theme = createTheme();
  theme = responsiveFontSizes(theme, {
    breakpoints: ["sm", "md", "lg"],
  });

  return (
    <ThemeProvider theme={theme}>
      <Stack padding={"3em 2em"}>
        <Stack spacing={4}>
          <Typography variant="h4" color={"#344054"}>
            MY Garage
          </Typography>
          {isLoading && (
            <Lottie
              loop
              animationData={loadingwheel}
              play
              style={{ width: "100%", height: 300 }}
            />
          )}
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={"2em"}
            alignItems={{ xs: "center" }}
            display={isLoading ? "none" : "inherit"}
            width={"100%"}
            overflow={"auto"}
          >
            {carsList && carsList.length > 0 ? (
              carsList.map((item, key) => {
                return <GarageCarCard key={key} cardData={item} />;
              })
            ) : (
              <Stack
                width={"100%"}
                alignItems={"center"}
                display={"flex"}
                flexDirection={"column"}
                fontWeight={"bold"}
              >
                <img src={NotFound} alt="" />
                NO RESULT FOUND
              </Stack>
            )}
          </Stack>
        </Stack>
      </Stack>
    </ThemeProvider>
  );
};

export default GarageComponent;
