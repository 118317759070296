import React, { useMemo, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const AuthCarousel = ({ sliderData }) => {
  const sliderValues = useRef(null);
  sliderValues.current = useMemo(() => {
    if (sliderData) {
      return JSON.parse(sliderData.value);
    }
  }, [sliderData]);

  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    dotsClass: "button__bar",
    arrows: false,
    cssEase: "linear",
  };

  return (
    <>
      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          position: "relative",
        }}
      >
        <Slider {...settings}>
          {sliderValues.current &&
            sliderValues.current.map((item, key) => {
              return (
                <div
                  key={key}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "end",
                    height: "100%",
                    gap: "20px",
                  }}
                >
                  <img
                    className="d-flex"
                    src={item.image}
                    alt="First slide"
                    style={{ width: "70%", objectFit: "cover" }}
                  />
                  <div>
                    <h4 style={{ color: "black" }}>{item.title}</h4>
                    <p style={{ fontSize: "1em", width: "60%" }}>
                      {item.description}
                    </p>
                  </div>
                </div>
              );
            })}
        </Slider>
      </div>
    </>
  );
};

export default AuthCarousel;
