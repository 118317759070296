import { Stack } from '@mui/system'
import React from 'react'
import DashboardComponent from '../../../components/profile/dashboard/DashboardComponent'

const DashboardScreen = () => {
    return (
        <Stack width={{ xs: "100vw", md: "75vw" }}>
            <DashboardComponent />
        </Stack>
    )
}

export default DashboardScreen