import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import TelInput from "../../reuseable/TelInput";
import StoreLocation from "./StoreLocation";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getShops, registerUser } from "../../../store/auth/actions";
import { LoadingButton } from "@mui/lab";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { getTokenFunc } from "../../../Firebase";
import { toast } from "react-hot-toast";

const schema = yup.object({
  phoneNumber: yup.string().required("Required"),
  password: yup
    .string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
  conformPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Required"),
  shopId: yup.string().required("Required"),
});

const RegisterForm = () => {
  const location = useLocation();
  const nav = useNavigate();
  const [token, setToken] = useState();
  const dispatch = useDispatch();
  const { shops, isLoading } = useSelector((state) => state.auth);
  const { register, handleSubmit, control, formState, reset } = useForm({
    resolver: yupResolver(schema),
  });
  const { errors } = formState;
  const onSubmit = (data) => {
    console.log(data);
    let phone = data.phoneNumber;
    phone = `+1${phone.replace(/[^0-9]/g, "")}`;
    const payload = {
      phoneNumber: phone,
      password: data.password,
      shopId: data.shopId,
    };
    reset();
    dispatch(registerUser(payload, nav));
  };
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  useEffect(() => {
    dispatch(getShops());
  }, []);
  useEffect(() => {
    getTokenFunc(setToken);
  }, []);

  useEffect(() => {
    if (token) {
      sessionStorage.setItem("fcmToken", token);
    }
  }, [token]);
  useEffect(() => {
    let Guest = location?.state?.guest;
    if (Guest) {
      let shopDetail = shops.find(
        (ii) => ii.tekmetricId == Guest?.guestUserInfo?.tekmetricShopId
      );
      reset({
        phoneNumber: Guest?.guestUserInfo?.phoneNumber.replace("+1", ""),
        shopId: shopDetail._id,
      });

      sessionStorage.setItem("guestInfo", JSON.stringify(Guest));
    } else if (sessionStorage.getItem("guestInfo")) {
      sessionStorage.removeItem("guestInfo");
    }
  }, []);

  return (
    <>
      <Typography variant="h5" fontWeight={"bold"} lineHeight={"1.3"}>
        Welcome to
        <br />
        Integrity 1st Automotive!
      </Typography>
      <Stack color={"#848FAC"} spacing={2}>
        <Typography className="fullWidthField" alignSelf={"start"}>
          Register your account
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <StoreLocation
            className="fullWidthField"
            name="shopId"
            shops={shops}
            control={control}
            rules={{ required: true }}
            label="Store Location"
            errors={errors}
          />
          <TelInput
            name="phoneNumber"
            type="tel"
            label="Phone Number"
            className="fullWidthField"
            control={control}
            rules={{
              required: true,
              max: 14,
              min: 14,
              maxLength: 14,
              pattern: /^\([0-9]{3}\)\s[0-9]{3}\-[0-9]{4}$/i,
            }}
            errors={errors}
          />
          <TextField
            name="password"
            className="TextField-without-border-radius fullWidthField"
            type={showPassword ? "text" : "password"}
            label="Create Password"
            error={errors?.password}
            {...register("password", { required: true, minLength: 6 })}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {errors?.password?.message && (
            <Typography
              mt={"0px !important"}
              color={"#D53939"}
              variant={"caption"}
            >
              {errors?.password?.message}
            </Typography>
          )}

          <TextField
            name="confirmPassword"
            className="TextField-without-border-radius fullWidthField"
            type={showPassword ? "text" : "password"}
            label="Confirm Password"
            error={errors?.conformPassword}
            {...register("conformPassword", { required: true, minLength: 6 })}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {errors?.conformPassword?.message && (
            <Typography
              mt={"0px !important"}
              color={"#D53939"}
              variant={"caption"}
            >
              {errors?.conformPassword?.message}
            </Typography>
          )}
          <LoadingButton
            loading={isLoading}
            variant="contained"
            type="submit"
            size="large"
            sx={{
              borderRadius: "54.6591px",
              bgcolor: "#F83D4B",
              fontSize: "small",
              p: 2,
              width: "100%",
            }}
          >
            Continue
          </LoadingButton>
        </form>
        <Typography alignSelf={"center"} paddingTop={"3em"}>
          Already have an account?{" "}
          <Link
            to={"/login"}
            style={{ color: "#F83D4B", textDecoration: "none" }}
          >
            Login
          </Link>
        </Typography>
      </Stack>
    </>
  );
};

export default RegisterForm;
