import { createTheme, responsiveFontSizes, ThemeProvider } from "@mui/material";
import { Stack } from "@mui/system";
import React, { useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import "../../utils/css/styles.css";

const RightSection = ({ children }) => {
  const { keys } = useSelector((state) => state.auth);

  let theme = createTheme();
  theme = responsiveFontSizes(theme, {
    breakpoints: ["sm", "md", "lg"],
  });
  const applogo = useRef(null);
  applogo.current = useMemo(() => {
    return keys?.find((appLogo) => appLogo.key === "InAppLogo");
  }, [keys]);
  return (
    <ThemeProvider theme={theme}>
      <Stack
        justifyContent={"center"}
        height={"100%"}
        width={"100%"}
        paddingX={{ xs: "2em", md: "4em", lg: "7em" }}
        spacing={3}
        position={"relative"}
      >
        <img
          className="app-logo"
          alt="app logo"
          src={applogo.current && applogo.current.value}
          style={{
            width: "6em",
            position: "absolute",
            top: "1em",
            left: "1em",
          }}
        />
        {children}
      </Stack>
    </ThemeProvider>
  );
};

export default RightSection;
