import React from 'react'
import ForgotPasswordOTPComponent from '../../components/auth/forgotPassword/ForgotPasswordOTPComponent'

const ForgotPasswordOTPScreen = () => {
    return (
        <>
            <ForgotPasswordOTPComponent />
        </>
    )
}

export default ForgotPasswordOTPScreen