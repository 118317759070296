import { Button, Grid, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import LeftSection from "../LeftSection";
import RightSection from "../RightSection";
import { useLocation, useNavigate } from "react-router";
import ReuseableModal from "../../reuseable/ReuseableModal";
import sadEmoji from "../../../assets/images/sad-emoji.svg";
import RegisterForm from "./RegisterForm";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearData } from "../../../store/auth/actions";

const RegisterComponent = () => {
  const { errorMessage, keys } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleNav = () => {
    dispatch(clearData(null));
    nav("/login");
  };
  const handleClearData = () => {
    handleClose();
    dispatch(clearData(null));
    window.location.reload(false);
  };

  const nav = useNavigate();

  useEffect(() => {
    if (errorMessage !== null) {
      handleOpen();
    }
  }, [errorMessage]);
  useEffect(() => {
    window.addEventListener("beforeunload", () => {
      dispatch(clearData(null));
    });
  }, []);

  return (
    <>
      <Grid container spacing={1} height={"100%"}>
        <Grid item xs="6" display={{ xs: "none", md: "flex" }}>
          <LeftSection />
        </Grid>
        <Grid item xs="12" md="6" display={"flex"} justifyContent={"center"}>
          <RightSection>
            <RegisterForm />
          </RightSection>
        </Grid>
      </Grid>
      {errorMessage && (
        <ReuseableModal handleClose={handleClose} open={open}>
          <img alt="" src={sadEmoji} width={100} />
          <Typography color={"#D23852"} fontWeight={"600"}>
            Already Exist!
          </Typography>
          <Typography
            textAlign={"center"}
            color="#667085"
            width={{ xs: "70vw", md: "50vw", lg: "40vw" }}
          >
            Sorry! provided phone number is already registered with Integrity
            1st Automotive. Change phone number of Login to your account.
          </Typography>
          <Stack
            direction={"row"}
            width={"100%"}
            justifyContent={"space-between"}
          >
            <Button
              variant="contained"
              type="submit"
              size="large"
              sx={{
                borderRadius: "54.6591px",
                background:
                  "linear-gradient(80.67deg, #C42E66 27.97%, #D23852 96.9%)",
                fontSize: "small",
                p: 2,
                width: "49%",
              }}
              onClick={() => handleClearData()}
            >
              Change Phone
            </Button>
            <Button
              variant="contained"
              type="submit"
              size="large"
              sx={{
                borderRadius: "54.6591px",
                background:
                  "linear-gradient(80.67deg, #C42E66 27.97%, #D23852 96.9%)",
                fontSize: "small",
                p: 2,
                width: "49%",
              }}
              onClick={() => handleNav()}
            >
              Login
            </Button>
          </Stack>
        </ReuseableModal>
      )}
    </>
  );
};

export default RegisterComponent;
