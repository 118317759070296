import { Divider, Link, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React, { useState } from "react";
import RewardMeter from "../../../assets/images/reward-meter.svg";
import ReuseableModal from "../ReuseableModal";
import Howtoearnfire from "../../../assets/images/howtoearnfire.svg";
import Howtoearn from "../../../assets/images/HOW_TO_EARN.png";
import Radeem from "../../../assets/images/HOW_TO_REDEEM.png";
import { RedLinearButton } from "../button/Buttons";
import RewardGuage from "../rewardGuage/RewardGuage";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getUser } from "../../../store/auth/actions";

const RewardCard = () => {
  let referEnum = {
    type: "REFERRAL_BONUS_VALUE",
    loyal_type: "LOYALTY_BONUS_VALUE",
  };
  const { user } = useSelector((state) => state.auth);
  const { keys } = useSelector((state) => state.dashboard);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUser());
  }, []);

  const [open, setOpen] = useState({
    hte: false,
    htr: false,
  });
  const handlehteOpen = () =>
    setOpen((prevState) => ({
      ...prevState,
      hte: true,
      // htr: false
    }));
  const handlehteClose = () =>
    setOpen((prevState) => ({
      ...prevState,
      hte: false,
      // htr: false
    }));

  //how to raeemd modal setup
  const handlehtrOpen = () =>
    setOpen((prevState) => ({
      ...prevState,
      // hte: false,
      htr: true,
    }));
  const handlehtrClose = () =>
    setOpen((prevState) => ({
      ...prevState,
      // hte: false,
      htr: false,
    }));

  return (
    <Stack
      direction={{ xs: "column", sm: "row" }}
      alignItems={"center"}
      justifyContent={"space-around"}
      sx={{
        border: "1px solid #E4E7EC",
        borderRadius: "10px",
        padding: "1em",
        backgroundColor: "#FFFFFF",
      }}
      width={{ lg: "59%", md: "auto" }}
    >
      <RewardGuage value={user?.rewardAmount} />

      <Stack
        width={{ xs: "100%", sm: "48%" }}
        borderLeft={{ sm: "1px solid #D0D5DD" }}
        borderTop={{ xs: "1px solid #D0D5DD", sm: "0" }}
        paddingLeft={{ sm: 2 }}
        paddingTop={{ xs: 2, sm: 0 }}
      >
        <Stack>
          <Typography variant="subtitle1">How to Earn?</Typography>
          <Typography color={"#667085"} variant="subtitle2">
            You Earn rewards for every visit and referral. The rewards earned
            can be redeemed for any vehicle and service you need!
          </Typography>
          <Typography
            component={Link}
            variant="subtitle2"
            underline="none"
            sx={{ cursor: "pointer" }}
            onClick={() => handlehteOpen()}
          >{`Learn More>>`}</Typography>
        </Stack>
        <ReuseableModal
          handleClose={handlehteClose}
          open={open.hte}
          width={"40%"}
        >
          <Stack
            sx={{
              backgroundImage: `url(${Howtoearnfire})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
          >
            <img alt="" src={Howtoearn} width={100} />
          </Stack>
          <Typography variant="h6" color={"#344054"}>
            How to Earn Reward Dollars
          </Typography>
          <Typography
            color={"#667085"}
            variant={"subtitle2"}
            textAlign={"center"}
          >
            By being registered on the app, you will automatically receive $
            {keys?.find((ii) => ii.key === referEnum.loyal_type)?.value || 0}{" "}
            after every service visit and $
            {keys?.find((ii) => ii.key === referEnum.type)?.value || 0} for
            every referral you and send our way for service!
          </Typography>
          <RedLinearButton
            handleClose={handlehteClose}
            text="I AM READY TO EARN"
            width={"80%"}
          />
        </ReuseableModal>
        <Stack>
          <Typography variant="subtitle1">How to Redeem?</Typography>
          <Typography color={"#667085"} variant="subtitle2">
            Simply show this screen at the front counter before service to
            redeem your reward against any service!
          </Typography>
          <Typography
            component={Link}
            variant="subtitle2"
            underline="none"
            sx={{ cursor: "pointer" }}
            onClick={() => handlehtrOpen()}
          >{`Learn More>>`}</Typography>
        </Stack>
        <ReuseableModal
          handleClose={handlehtrClose}
          open={open.htr}
          width={"40%"}
        >
          <img alt="" src={Radeem} width={100} />
          <Typography variant="h6" color={"#344054"}>
            How to Redeem your Reward Dollars
          </Typography>
          <Typography
            color={"#667085"}
            variant={"subtitle2"}
            textAlign={"center"}
          >
            To redeem your reward dollars, simply show your app dashboard at the
            front-counter before service, and we take care of the rest!
          </Typography>
          <RedLinearButton
            handleClose={handlehtrClose}
            text="I AM READY  TO REDEEM"
            width={"80%"}
          />
        </ReuseableModal>
      </Stack>
    </Stack>
  );
};

export default RewardCard;
