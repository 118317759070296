import React, { useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useSelector } from "react-redux";
import { InputLabel, Typography } from "@mui/material";

const GuestDesiredShop = ({serviceType, setServiceType, register, error,
  setShopDetail,
  setValue
}) => {
  const { shops } = useSelector((state) => state.auth);
  const [shopId, setShopId] = React.useState("");
  const { onChange,onBlur,name,ref } = {...register("tekmetricShopId", { required: true }),};
  const handleChange = (event) => {
    setShopId(event.target.value);
    onChange(event);
    if(setShopDetail) {
      shops.find((ii) => ii.tekmetricId === event.target.value) &&
        setShopDetail(
          shops.find((ii) => ii.tekmetricId === event.target.value)
        );
    }
  };
  useEffect(() => {
    let searchParams = new URLSearchParams(window.location.search);
    let queryShopId = searchParams.get("location");
    const shopIdMatched = shops?.find(
      (shop) => shop.tekmetricId.toString() === queryShopId
    );
    if (shopIdMatched) {
      setValue('tekmetricShopId',queryShopId)
      setShopId(queryShopId);
      setShopDetail(shopIdMatched)
    }
  }, [shops]);

  return (
    <>
      <Typography fontWeight={"bold"}>1. Which shop is near to you?</Typography>
      <FormControl sx={{ mt: "1em !important" }}>
        <InputLabel id="service-reason">Select desired Shop</InputLabel>
        <Select
          labelId="service-reason"
          id="service-reason-id"
          value={shopId}
          onChange={handleChange}
          label="Select desired Shop"
          sx={{ backgroundColor: "white" }}
          name={name}
          ref={ref}
          onBlur={onBlur}
          error={error && error.tekmetricShopId}
        >
          <MenuItem disabled value="">
            <em>Services</em>
          </MenuItem>
          {shops &&
            shops.map((item, key) => {
              return (
                <MenuItem key={`tekmetric-${key}-${item._id}`} value={item.tekmetricId}>
                  {item?.address?.fullAddress}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
    </>
  );
};
export default GuestDesiredShop;
