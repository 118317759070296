// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: "AIzaSyAHsIcdovQPHqSThRwmoD_Gw-tXmOJSlss",
  authDomain: "integrity-loyalty.firebaseapp.com",
  projectId: "integrity-loyalty",
  storageBucket: "integrity-loyalty.appspot.com",
  messagingSenderId: "988776500446",
  appId: "1:988776500446:web:672c48fde3526bbd75864f",
  measurementId: "G-86C6E8FRWZ",
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);
export const getTokenFunc = (setTokenFound) => {
  return getToken(messaging, {
    vapidKey:
      "BJI1_Ke_ayK9FuagVuNqCjvcunN7gAUV4NT1A5sRGNJZsGwM0kSdkzvlnv1hNcOWn1A1YkbSdMxCNsycX096-VA",
  })
    .then((currentToken) => {
      if (currentToken) {
        setTokenFound(true);
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
        setTokenFound(false);
        // shows on the UI that permission is required
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
      // catch error while creating client token
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
});
