import { GET_DYNAMIC_KEYS_FAILED, GET_DYNAMIC_KEYS_SUCCESS } from "../auth/actionTypes";
import {
  STOP_LOADING,
  START_LOADING,
  GET_OFFERS,
  GET_OFFERS_SUCCESS,
  GET_OFFERS_FAILED,
  GET_BLOGS,
  GET_BLOGS_SUCCESS,
  GET_BLOGS_FAILED,
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_FAILED,
  READ_ALL_NOTIFICATIONS,
  READ_ALL_NOTIFICATIONS_SUCCESS,
  READ_ALL_NOTIFICATIONS_FAILED,
  CUSTOMER_IO_EVENT_FAILED,
  CUSTOMER_IO_EVENT_SUCCESS,
  CUSTOMER_IO_EVENT,
  CUSTOMER_IO_EVENT_ANONYMOUS,
} from "./actionTypes";

//GET_OFFERS action
export const getOffers = () => {
  return {
    type: GET_OFFERS,
  };
};

//GET_OFFERS success action
export const getOffersSuccess = (data) => {
  return {
    type: GET_OFFERS_SUCCESS,
    payload: data,
  };
};

//GET_OFFERS failed action
export const getOffersFailed = (data) => {
  return {
    type: GET_OFFERS_FAILED,
    payload: data?.data?.message,
  };
};

//GET_BLOGS action
export const getBlogs = () => {
  return {
    type: GET_BLOGS,
  };
};

//GET_BLOGS success action
export const getBlogsSuccess = (data) => {
  return {
    type: GET_BLOGS_SUCCESS,
    payload: data,
  };
};

//GET_BLOGS failed action
export const getBlogsFailed = (data) => {
  return {
    type: GET_BLOGS_FAILED,
    payload: data?.data?.message,
  };
};

//GET_NOTIFICATIONS action
export const getNotifications = () => {
  return {
    type: GET_NOTIFICATIONS,
  };
};

//GET_NOTIFICATIONS success action
export const getNotificationsSuccess = (data) => {
  return {
    type: GET_NOTIFICATIONS_SUCCESS,
    payload: data,
  };
};

//GET_NOTIFICATIONS failed action
export const getNotificationsFailed = (data) => {
  return {
    type: GET_NOTIFICATIONS_FAILED,
    payload: data?.data?.message,
  };
};

//READ_ALL_NOTIFICATIONS action
export const readAllNotifications = () => {
  return {
    type: READ_ALL_NOTIFICATIONS,
  };
};

//READ_ALL_NOTIFICATIONS success action
export const readAllNotificationsSuccess = (data) => {
  return {
    type: READ_ALL_NOTIFICATIONS_SUCCESS,
    payload: data,
  };
};

//READ_ALL_NOTIFICATIONS failed action
export const readAllNotificationsFailed = (data) => {
  return {
    type: READ_ALL_NOTIFICATIONS_FAILED,
    payload: data?.data?.message,
  };
};

//CUSTOMER_IO_EVENT action
export const customerIoEvent = (payload, eventName) => {
  return {
    type: CUSTOMER_IO_EVENT,
    payload: payload,
    eventName: eventName,
  };
};
export const customerIoEventAnonymous = (payload, eventName) => {
  return {
    type: CUSTOMER_IO_EVENT_ANONYMOUS,
    payload: payload,
    eventName: eventName,
  };
};

//CUSTOMER_IO_EVENT success action
export const customerIoEventSuccess = (data) => {
  return {
    type: CUSTOMER_IO_EVENT_SUCCESS,
    payload: data,
  };
};

//CUSTOMER_IO_EVENT failed action
export const customerIoEventFailed = (data) => {
  return {
    type: CUSTOMER_IO_EVENT_FAILED,
    payload: data?.data?.message,
  };
};

//start loading action
export const startLoading = (data) => ({
  type: START_LOADING,
  payload: data,
});

//stop loading action
export const stopLoading = (data) => ({
  type: STOP_LOADING,
  payload: {
    action: data,
  },
});

//GET_DYNAMIC_KEYS success action
export const getDynamicKeysSuccess = (data) => {
  return {
    type: GET_DYNAMIC_KEYS_SUCCESS,
    payload: data,
  };
};

//GET_DYNAMIC_KEYS failed action
export const getDynamicKeysFailed = (data) => {
  return {
    type: GET_DYNAMIC_KEYS_FAILED,
    payload: data?.data?.message,
  };
};


