import React, { useEffect, useRef, useState } from "react";
import Modal from "@mui/material/Modal";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../utils/css/styles.css";
import { Button, Typography, IconButton } from "@mui/material";
import { Stack } from "@mui/system";
import { RedSolidButton } from "./button/Buttons";
import rf1 from "../../assets/images/REFER & EARN 1.png";
import rf2 from "../../assets/images/REFER & EARN 2.png";
import rf3 from "../../assets/images/REFER & EARN 3.png";
import copy from "copy-to-clipboard";
import Copy from "../../assets/images/copy.svg";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { customerIoEvent } from "../../store/dashboard/actions";

const RefferModal = ({ handleClose, open, referralMessage, referValue }) => {
  const token = localStorage.getItem("token");
  const sliderRef = useRef(null);
  const [buttonText, setButtonText] = useState("NEXT");
  const [slidDex, setSlideDex] = useState(0);
  const imgArr = [
    {
      img: rf1,
      para: "Share your referral link with any friend or family member.",
    },
    {
      img: rf2,
      para: "The link will allow your referral to instantly book an appointment",
    },
    {
      img: rf3,
      para: `On the completion of service, both You and your referral will earn ${
        referValue ? "$ " + referValue : ""
      }.`,
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplaySpeed: 3000,
    dotsClass: "centered_button__bar",
    arrows: true,
    cssEase: "linear",
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: "40%",
    minHeight: "70%",
    bgcolor: "#FFFFFF",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const handleNext = (text) => {
    if (sliderRef.current?.innerSlider?.state?.currentSlide == 2) {
      copy(text);
      toast.success("Referral Code Copied Successfully");
      handleClose();
    }
    switch (sliderRef.current?.innerSlider?.state?.currentSlide) {
      case 1:
        setButtonText("I AM READY TO REFER & EARN");
        break;
      default:
        setButtonText("NEXT");
        break;
    }
    sliderRef.current?.slickNext();
  };
  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <>
          <Stack
            sx={style}
            spacing="1em"
            width={{ xs: "90%", md: "39.125em" }}
            height={"39.5em"}
            alignItems={"center"}
            justifyContent={"space-around"}
          >
            <Typography variant="h5" fontWeight={"bold"}>
              REFER & EARN
            </Typography>
            <RefferalCode referralMessage={referralMessage} />
            <div className="slick-container">
              <Slider {...settings} ref={sliderRef} value={slidDex}>
                {imgArr.map((itm, inx) => {
                  return (
                    <Stack
                      display={"flex !important"}
                      justifyContent="space-between"
                      alignItems="center"
                      key={inx}
                    >
                      <img
                        className="img-reffer"
                        src={itm?.img}
                        alt="Slider Image"
                      />
                      <p
                        style={{
                          fontSize: "1em",
                          width: "60%",
                          color: "black",
                          textAlign: "center",
                        }}
                      >
                        {itm?.para}
                      </p>
                    </Stack>
                  );
                })}
              </Slider>
            </div>
            <Button
              onClick={() => handleNext(referralMessage)}
              variant={"contained"}
              sx={{
                borderRadius: "54.6591px",
                background:
                  "linear-gradient(80.67deg, #c42e66 27.97%, #d23852 96.9%)",
                fontSize: "small",
                p: 2,
                width: "80%",
              }}
            >
              {buttonText}
            </Button>
          </Stack>
        </>
      </Modal>
    </>
  );
};
const RefferalCode = () => {
  const handleCopyText = (text) => {
    copy("https://app.integrity1auto.com/reff/" + text);
    toast.success("Referral Code Copied Successfully");
  };
  const { user } = useSelector((state) => state.auth);
  return (
    <Stack
      direction={"row"}
      width={"100%"}
      color={"#D13753"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Typography
        color={"#D13753"}
        variant={"body2"}
      >{`https://app.integrity1auto.com/reff/${user?.referralCode}`}</Typography>
      <IconButton
        aria-label="copy_text"
        onClick={() => handleCopyText(user?.referralCode)}
      >
        <img src={Copy} alt="" />
      </IconButton>
    </Stack>
  );
};

export default RefferModal;
