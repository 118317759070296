import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAppontmentRec } from "../../../store/appointments/actions";
import { InputLabel, Typography } from "@mui/material";

const GuestAppointmentServices = ({
  serviceType,
  setServiceType,
  register,
  error,
}) => {
  const { recomended } = useSelector((state) => state.appointments);

  const handleChange = (event) => {
    setServiceType(event.target.value);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAppontmentRec());
  }, []);

  return (
    <>
      <Typography fontWeight={"bold"}>
        3. What are you looking to get done
      </Typography>
      <FormControl sx={{ mt: "1em !important" }}>
        <InputLabel id="service-reason">Select Appointment Purpose</InputLabel>
        <Select
          labelId="service-reason"
          id="service-reason-id"
          value={serviceType}
          onChange={handleChange}
          label="Select Appointment Purpose"
          sx={{ backgroundColor: "white" }}
          error={error && error.appointmentReason}
          {...register("appointmentReason", { required: true })}
        >
          <MenuItem disabled value="">
            <em>Services</em>
          </MenuItem>
          {recomended &&
            recomended.map((item, key) => {
              return (
                <MenuItem key={key} value={item._id}>
                  {item.title}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
    </>
  );
};
export default GuestAppointmentServices;
