import styled from "@emotion/styled";
import { Button, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import storage from "redux-persist/lib/storage";
import { deleteUser, userFlush } from "../../../store/auth/actions";
import ReuseableModal from "../../reuseable/ReuseableModal";

const DeleteAccountModal = ({ logout, logoutState }) => {
  const CustomButton = styled(Button)(({ theme }) => ({
    "&:hover": {
      backgroundColor: "red", // your hover styles here
    },
  }));
  const nav = useNavigate();
  const dispatch = useDispatch();
  const handleLogoutClose = () => {
    dispatch(deleteUser(nav));
    logoutState(false);
  };
  return (
    <>
      <ReuseableModal
        handleClose={() => logoutState(false)}
        open={logout}
        width={"fit-content"}
      >
        <Typography variant="h6" fontWeight={"bold"}>
          Are you sure, you want to delete your account?
        </Typography>
        <Stack direction={"row"} width="100%" spacing={2}>
          <CustomButton
            sx={{
              borderRadius: "54.6591px",
              bgcolor: "#F83D4B",
              fontSize: "small",
              p: 2,
              color: "white",
              width: "100%",
            }}
            onClick={() => handleLogoutClose()}
          >
            Yes
          </CustomButton>
          <CustomButton
            sx={{
              borderRadius: "54.6591px",
              bgcolor: "#F83D4B",
              fontSize: "small",
              p: 2,
              color: "white",
              width: "100%",
            }}
            onClick={() => logoutState(false)}
          >
            Cancel
          </CustomButton>
        </Stack>
      </ReuseableModal>
    </>
  );
};

export default DeleteAccountModal;
