import { Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { edgedSlots } from "../../../data/data";
import { getShops } from "../../../store/auth/actions";
import AppointmentCard from "../../reuseable/cards/AppointmentCard";
const GuestDateTimePicker = ({
  startDate,
  setStartDate,
  index,
  timeValue,
  setTimeValue,
  outerTimeStamps,
  setOuterTimeStamps,
  shopDetail,
}) => {
  const { shops } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getShops());
  }, []);

  const day = startDate.getDay() - 1;
  const filteredSlots = useRef(null);
  const [filteredSlotsState, setFilteredSlotsState] = useState([]);

  useEffect(() => {
    if (shops) {
      if (shopDetail?.openingHours?.length && shopDetail?.openingHours[day]) {
        let from = shopDetail?.openingHours[day].from.substr(0, 2);
        let end = Number(shopDetail?.openingHours[day].till.substr(0, 2));
        let endArr = [];
        let fromArr = [];
        if (shopDetail?.openingHours[day].from) {
          for (var i = Number(from); i <= 12; i++) {
            if (i === 12) {
              fromArr.push(`${i}:30 pm`);
            } else {
              fromArr.push(`${i}:30 am`);
            }
          }
        }
        if (shopDetail?.openingHours[day].till) {
          for (var i = end; i > 0; i--) {
            endArr.push(`${i}:30 pm`);
          }
        }

        filteredSlots.current = fromArr.concat(endArr.reverse());
      } else {
        filteredSlots.current = [];
      }
    }
    if (filteredSlots.current) {
      filteredSlots?.current?.pop();
      setFilteredSlotsState(filteredSlots.current);
    }
  }, [shopDetail?.openingHours?.length, shops, day]);

  const isWeekday = (date) => {
    const day = date.getDay();
    return day !== 0 && day !== 6;
  };
  const handleTimeStamps = (time) => {
    setTimeValue(time);
    setOuterTimeStamps({
      ...outerTimeStamps,
      isAfterHours: false,
      isEarlyBird: false,
    });
  };
  const handleOuterTimeStamps = (key) => {
    if (key) {
      setOuterTimeStamps({
        ...outerTimeStamps,
        isAfterHours: true,
        isEarlyBird: false,
      });
    } else {
      setOuterTimeStamps({
        ...outerTimeStamps,
        isEarlyBird: true,
        isAfterHours: false,
      });
    }
  };
  return (
    <AppointmentCard>
      <Typography
        mb="1em"
        fontWeight={"bold"}
      >{`2. Select Date and Time`}</Typography>
      <Stack
        direction={{ xs: "column", md: "row" }}
        justifyContent={"space-between"}
      >
        {/* Date Piicker */}
        <Stack width={{ xs: "100%", md: "48%" }}>
          <Typography variant="body1" paddingBottom={2}>
            Select Date
          </Typography>
          <DatePicker
            selected={startDate}
            minDate={new Date()}
            onChange={(date) => setStartDate(date)}
            inline
            filterDate={isWeekday}
            fixedHeight
          />
        </Stack>

        {/* Time Picker  */}
        <Stack width={{ xs: "100%", md: "48%" }} spacing={2}>
          <Typography variant="body1" paddingBottom={2}>
            Select Time
          </Typography>
          <Grid container paddingBottom={2} mt={"0 !important"}>
            {filteredSlotsState.length
              ? filteredSlotsState.map((time, index) => {
                  return (
                    <Grid
                      item
                      xs={4}
                      md={3}
                      key={index}
                      pr={"1em"}
                      pb={"1em"}
                      onClick={() => handleTimeStamps(time)}
                    >
                      <Stack
                        borderRadius={1}
                        fontSize={"0.8em"}
                        className={timeValue === time ? "styledTime" : ""}
                        bgcolor={"#FFFFFF"}
                        border={"1px solid rgba(0, 0, 0, 0.12)"}
                        paddingX={{ xs: 0, md: 2 }}
                        paddingY={2}
                        textAlign={"center"}
                      >
                        {index === 0 && shopDetail?.openingHours?.length > 0
                          ? shopDetail?.openingHours[day].from
                          : time}
                      </Stack>
                    </Grid>
                  );
                })
              : null}
          </Grid>
          <Grid container>
            {edgedSlots.map((time, key) => {
              return (
                <Grid
                  item
                  xs={12}
                  md={6}
                  pr={"1em"}
                  pb={"1em"}
                  onClick={() => {
                    setTimeValue(time.time);
                    handleOuterTimeStamps(key);
                  }}
                >
                  <Stack
                    borderRadius={1}
                    className={timeValue === time.time ? "styledTime" : ""}
                    bgcolor={"#F2F4F7"}
                    border={"1px solid #D0D5DD"}
                    paddingX={2}
                    paddingY={2}
                  >
                    <Stack direction={"row"} alignItems={"center"}>
                      <img src={time.img} alt="" />
                      <Typography variant="body2">{time.label}</Typography>
                    </Stack>
                    <Typography variant="caption" color={"#98A2B3"}>
                      {time.value}
                    </Typography>
                  </Stack>
                </Grid>
              );
            })}
          </Grid>
        </Stack>
      </Stack>
    </AppointmentCard>
  );
};

export default GuestDateTimePicker;
