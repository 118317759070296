import { Stack, Box } from '@mui/system';
import React from 'react';
import AppointmentComponent from '../../../components/profile/appointment/AppointmentComponent';
import { Typography } from '@mui/material';
import './AppointmentScreen.css'; // Import the CSS file for the animation

const AppointmentScreen = () => {
    return (
        <Stack width={{ xs: "100vw", md: "75vw" }}>
            {/* Banner with moving text */}
            <Box
                sx={{
                    width: "100%",
                    background: "linear-gradient(90deg, #ca3457, #851730)",
                    color: "white",
                    overflow: "hidden", // Hide overflow for the scrolling effect
                    padding: "2px 0", // Add some padding for spacing
                }}
            >
                <Typography 
                    className="moving-text" 
                    variant="body1"
                    align="center"
                >
                   We’re open Monday to Friday from 7:30am - 6:00pm. We’re closed on weekends.
                </Typography>
            </Box>

            <AppointmentComponent />
        </Stack>
    );
}

export default AppointmentScreen;
