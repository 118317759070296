import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router";
import { getUser } from "../../store/auth/actions";

const PrivateRoutes = ({ children }) => {
  const dispatch = useDispatch();
  const authenticationFailedMessage = useSelector(
    (state) => state.auth?.authenticationFailedMessage
  );

  useEffect(() => {
    dispatch(getUser());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isProfileExist = useMemo(() => {
    return authenticationFailedMessage ===
      "Not authenticated, profile not found!"
      ? false
      : true;
  }, [authenticationFailedMessage]);

  if (!isProfileExist) {
    localStorage.clear();
  }
  const isAuthenticated = localStorage.getItem("token");
  if (!isProfileExist || !isAuthenticated) {
    return <Navigate to={"/login"} replace />;
  }
  return children;
};

export default PrivateRoutes;
