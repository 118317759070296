import { Stack, Box } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import GuestAppointmentComponent from "../../../components/profile/guestUser/GuestAppointmentComponent";
import { Typography } from "@mui/material";
import './GuestAppointmentScreen.css'; // Make sure to create this CSS file

const GuestAppointmentScreen = () => {
  const isAuthenticated = localStorage.getItem("token");
  const nav = useNavigate();
  
  useEffect(() => {
    if (isAuthenticated) {
      nav("/appointment");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack width={{ xs: "100vw" }}>
      {/* Banner with moving text */}
      <Box
        sx={{
          width: "100%",
          background: "linear-gradient(90deg, #ca3457, #851730)", // Gradient background
          color: "white",
          overflow: "hidden", // Hide overflow for the scrolling effect
          padding: "2px 0", // Add some padding for spacing
        }}
      >
        <Typography 
          className="moving-text" 
          variant="body1"
          align="center"
        >
         We’re open Monday to Friday from 7:30am - 6:00pm. We’re closed on weekends.
        </Typography>
      </Box>

      {/* Show Guest Appointment Component if not authenticated */}
      {!isAuthenticated && <GuestAppointmentComponent />}
    </Stack>
  );
};

export default GuestAppointmentScreen;
