import React, { useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import NotFound from "../../../assets/images/NotFound.svg";
import DefaultImage from "../../../assets/images/DefaultImage.svg";
import "../../../utils/css/styles.css";
import { Button, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { ClipLoader } from "react-spinners";

const CarouselCard = () => {
  const [index, setIndex] = useState(0);
  const { offers, isLoading } = useSelector((state) => state.dashboard);
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  const nav = useNavigate();
  const handleNav = (ctaKind, ctaValue) => {
    if (ctaKind === "APPOINTMENT") nav("/appointment");
    else if (ctaKind === "URL") {
      // Check if ctaValue starts with a protocol
      const hasProtocol = /^(https?|ftp):\/\//.test(ctaValue);

      // If it doesn't have a protocol, open it without modifying
      if (!hasProtocol) {
        window.open(`//${ctaValue}`, "_blank");
      } else {
        window.open(ctaValue, "_blank");
      }
    }
  };
  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };

  return (
    <>
      <ClipLoader
        color={"#D13851"}
        loading={isLoading}
        cssOverride={override}
        size={50}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
      <Carousel
        activeIndex={index}
        onSelect={handleSelect}
        controls={false}
        indicators={Boolean(offers?.total)}
        className={isLoading ? "d-none" : "d-block"}
      >
        {offers?.total > 0 ? (
          offers?.offers.slice(0, 3).map((item, key) => {
            return (
              <Carousel.Item
                key={key}
                style={{ height: "100% !important" }}
                onClick={() => handleNav(item.ctaKind, item.ctaValue)}
              >
                {/* <img
                className="d-block w-100"
                src={item.imageURL}
                alt="First slide"
                width={60}
              /> */}
                <Stack
                  direction="horizontal"
                  className="position-absolute top-0 bottom-0 ps-5 pt-5  m-0"
                  sx={{
                    backgroundImage: `url(${item.imageURL}), url(${DefaultImage})`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    boxShadow: "rgb(0 0 0 / 35%) 0px 108px 100px 5px inset",
                  }}
                  width={"100%"}
                >
                  <Carousel.Caption color="white">
                    <h3>{item.title}</h3>
                    <p>{item.description}</p>
                    <Button
                      variant="text"
                      sx={{ width: "fit-content", padding: 0 }}
                    >{`Learn more>>`}</Button>
                  </Carousel.Caption>
                  {/* <img src={item.imageURL} width={"50%"} /> */}
                </Stack>
              </Carousel.Item>
            );
          })
        ) : (
          <Stack
            width={"100%"}
            alignItems={"center"}
            justifyContent={"center"}
            sx={{ display: "flex", flexDirection: "column" }}
          >
            <img src={NotFound} alt="" />
            NO RESULT FOUND
          </Stack>
        )}
      </Carousel>
    </>
  );
};

export default CarouselCard;
