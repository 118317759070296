import {
  Divider,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  footerNavigation,
  footerPrivacyTerm,
  footerSocialIcons,
} from "../../data/data";
import { getDynamicKeys } from "../../store/auth/actions";

const Footer = () => {
  const { keys } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDynamicKeys());
  }, []);
  const handleNav = (value) => {
    const route = keys.find((address) => address.key === value);
    window.open(route.value, "_blank");
  };
  const footerLogo = useRef(null);
  useEffect(() => {
    footerLogo.current = keys?.find((appLogo) => appLogo.key === "InAppLogo");
  }, [keys]);
  const date = new Date().getFullYear();
  return (
    <Stack sx={{ backgroundColor: "black" }}>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        color={"#FFFFFF"}
        justifyContent={"space-between"}
        padding={"3em 1em"}
        spacing={{ xs: 2, sm: "0" }}
      >
        <Typography>
          {`© ${date} Integrity 1st Automotive. All Rights Reserved.`}
        </Typography>
        <Stack
          flexDirection="row"
          justifyContent={{ xs: "center", sm: "inherit" }}
        >
          {footerSocialIcons.map(({ icon, value }, i) => (
            <ListItem key={i} disablePadding sx={{ width: "fit-content" }}>
              <ListItemButton
                sx={{ padding: "0 !important" }}
                onClick={() => handleNav(value)}
              >
                <ListItemIcon
                  sx={{ color: "#FFFFFF", justifyContent: "center" }}
                >
                  {icon}
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Footer;
