export const getDisabledDayIndexes = (openingHours) => {
  return openingHours
    .map((item, index) => {
      if (item.from === "" || item.till === "") {
        return index === openingHours.length - 1 ? 0 : index + 1; // Adjust for last index
      }
      return -1; // Not a disabled day
    })
    .filter((index) => index !== -1); // Filter out invalid indexes
};
