import { Box, FormHelperText, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import LeftSection from "../LeftSection";
import RightSection from "../RightSection";
import { useNavigate } from "react-router";
import { Controller, useForm } from "react-hook-form";
import { MuiOtpInput } from "mui-one-time-password-input";
import { useDispatch, useSelector } from "react-redux";
import { resetPasswordOTP } from "../../../store/auth/actions";
import { LoadingButton } from "@mui/lab";

const ForgotPasswordOTPComponent = () => {
  const { isLoading, keys } = useSelector((state) => state.auth);
  const nav = useNavigate();

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      otp: "",
    },
  });
  const dispatch = useDispatch();
  const onSubmit = (data) => {
    const number = sessionStorage.getItem("resetPhone");
    const payload = {
      phoneNumber: number,
      code: data.otp,
    };
    reset();
    dispatch(resetPasswordOTP(payload, nav));
    sessionStorage.removeItem("resetPhone");
  };

  return (
    <>
      <Grid container spacing={1} height={"100%"}>
        <Grid item xs="6" display={{ xs: "none", md: "flex" }}>
          <LeftSection />
        </Grid>
        <Grid item xs="12" md="6" display={"flex"} justifyContent={"center"}>
          <RightSection>
            <Typography variant="h4" fontWeight={"bold"} lineHeight={"1.3"}>
              Enter 6-digit code we've sent to your Phone
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack color={"#848FAC"} spacing={2}>
                <Controller
                  name="otp"
                  control={control}
                  rules={{ validate: (value) => value.length === 6 }}
                  render={({ field, fieldState }) => (
                    <Box>
                      <MuiOtpInput
                        sx={{
                          gap: 1,
                        }}
                        {...field}
                        length={6}
                      />
                      {fieldState.invalid ? (
                        <FormHelperText error>OTP invalid</FormHelperText>
                      ) : null}
                    </Box>
                  )}
                />
                <LoadingButton
                  loading={isLoading}
                  variant="contained"
                  type="submit"
                  size="large"
                  sx={{
                    borderRadius: "54.6591px",
                    bgcolor: "#F83D4B",
                    fontSize: "small",
                    p: 2,
                    width: "100%",
                  }}
                >
                  Reset
                </LoadingButton>
              </Stack>
            </form>
          </RightSection>
        </Grid>
      </Grid>
    </>
  );
};

export default ForgotPasswordOTPComponent;
