import { Stack } from "@mui/material";
import React from "react";
import GarageComponent from "../../../components/profile/garage/GarageComponent";

const GarageScreen = () => {
  return (
    <Stack width={{ xs: "100vw", md: "75vw" }}>
      <GarageComponent />
    </Stack>
  );
};

export default GarageScreen;
