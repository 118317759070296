import React from 'react'
import SignUpComponent from '../../components/auth/signup/SignUpComponent'

const SignUpScreen = () => {
    return (
        <>
            <SignUpComponent />
        </>
    )
}

export default SignUpScreen