import { Stack } from "@mui/material";
import React from "react";
import ServiceDueComponent from "../../../components/profile/garage/ServiceDueComponent";

const ServiceDueScreen = () => {
  return (
    <Stack width={{ xs: "100vw", md: "75vw" }}>
      <ServiceDueComponent />
    </Stack>
  );
};

export default ServiceDueScreen;
