import { Grid, Stack, TextField, Typography } from "@mui/material";
import React from "react";
import LeftSection from "../LeftSection";
import RightSection from "../RightSection";
import "../../../utils/css/styles.css";
import { useNavigate } from "react-router";
import SignupForm from "./SignupForm";

const SignUpComponent = () => {
  const nav = useNavigate();
  const handleNav = () => {
    nav("/");
  };

  return (
    <>
      <Grid container spacing={1} height={"100%"}>
        <Grid item xs="6" display={{ xs: "none", md: "flex" }}>
          <LeftSection />
        </Grid>
        <Grid item xs="12" md="6" display={"flex"} justifyContent={"center"}>
          <RightSection>
            <SignupForm />
          </RightSection>
        </Grid>
      </Grid>
    </>
  );
};

export default SignUpComponent;
