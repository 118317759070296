import MainRoutes from "./utils/routs/MainRoutes";
import "bootstrap/dist/css/bootstrap.min.css";
import { createTheme, ThemeProvider } from "@mui/material";

const font = "'Inter', sans-serif";
const theme = createTheme({
  typography: {
    fontFamily: font,
  },
});
function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className="App" style={{ height: "100vh" }}>
        <MainRoutes />
      </div>
    </ThemeProvider>
  );
}

export default App;
