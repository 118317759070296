import {
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Card,
  CardContent,
  CardHeader,
} from "@mui/material";
import React from "react";
import ServiceTask from "../../../assets/images/service-task.svg";
import NotFound from "../../../assets/images/NotFound.svg";
import { ConvertDate } from "../ConvertDate";

const ServiceHistoryCard = ({ serviceData, serviceDate }) => {
  const Date = ConvertDate(serviceDate);
  return (
    <Card
      sx={{
        maxWidth: 302,
        minWidth: 302,
        bgcolor: "#F2F4F7",
        borderRadius: "10px",
        paddingY: "1em",
        marginRight: "1em",
      }}
      // direction={"column"}
      style={{
        flexDirection: "column",
        flexWrap: "nowrap",
      }}
    >
      {serviceData ? (
        <>
          <Typography px="18px" fontWeight={"bold"}>
            {Date}
          </Typography>
          {Object.keys(serviceData).map(function (key, index) {
            const data = { ...serviceData[key] };
            return (
              <ListItem key={`service-history-${index}`}>
                <ListItemIcon
                  sx={{
                    minWidth: "fit-content",
                    mr: "6px",
                  }}
                >
                  <img src={ServiceTask} alt="" />
                </ListItemIcon>
                <ListItemText primary={data.name} />
              </ListItem>
            );
          })}
        </>
      ) : (
        <Grid
          item
          xs={12}
          textAlign={"center"}
          justifyContent="center"
          alignItems="center"
          display={"flex"}
          flexDirection={"column"}
        >
          <img src={NotFound} alt="" />
          NO RESULT FOUND
        </Grid>
      )}
    </Card>
  );
};

export default ServiceHistoryCard;
