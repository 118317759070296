import {
  Card,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import ServiceTask from "../../../assets/images/service-task.svg";

const ServiceDueCard = ({ serviceData, bgcolor, isDue }) => {
  return (
    <Card
      sx={{
        maxWidth: 302,
        minWidth: 302,
        bgcolor: "#F2F4F7",
        borderRadius: "10px",
        paddingY: "1em",
      }}
    >
      <Stack>
        <Typography
          textAlign={"center"}
          variant={"body1"}
          fontWeight={"600"}
        >{`Due at ${serviceData[0]?.due_mileage} miles`}</Typography>
      </Stack>
      {serviceData && (
        <Grid container xs={12}>
          {Object.keys(serviceData).map(function (key, index) {
            const data = { ...serviceData[key] };
            return (
              <Grid item xs={12}>
                <ListItem>
                  <ListItemIcon
                    sx={{
                      minWidth: "fit-content",
                      mr: "6px",
                    }}
                  >
                    <img src={ServiceTask} alt="" />
                  </ListItemIcon>
                  <ListItemText
                    primary={data.desc}
                    sx={{ fontSize: "12px !important" }}
                  />
                </ListItem>
              </Grid>
            );
          })}
        </Grid>
      )}
    </Card>
  );
};

export default ServiceDueCard;
