export const GET_OFFERS = "get_offers";
export const GET_OFFERS_SUCCESS = "get_offers_success";
export const GET_OFFERS_FAILED = "get_offers_failed";

export const GET_BLOGS = "get_blogs";
export const GET_BLOGS_SUCCESS = "get_blogs_success";
export const GET_BLOGS_FAILED = "get_blogs_failed";



export const GET_NOTIFICATIONS = "get_notifications";
export const GET_NOTIFICATIONS_SUCCESS = "get_notifications_success";
export const GET_NOTIFICATIONS_FAILED = "get_notifications_failed";

export const READ_ALL_NOTIFICATIONS = "read_all_notifications";
export const READ_ALL_NOTIFICATIONS_SUCCESS = "read_all_notifications_success";
export const READ_ALL_NOTIFICATIONS_FAILED = "read_all_notifications_failed";


export const CUSTOMER_IO_EVENT = "customer_io_event";

export const CUSTOMER_IO_EVENT_ANONYMOUS = "customer_io_event_anonymous";
export const CUSTOMER_IO_EVENT_SUCCESS = "customer_io_event_success";
export const CUSTOMER_IO_EVENT_FAILED = "customer_io_event_failed";
export const STOP_LOADING = "stop_loading";
export const START_LOADING = "start_loading";

export const CLEAR_DATA = "clear_data";
export const CLEAR_DATA_SUCCESS = "clear_data_success";
