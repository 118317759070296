import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAppontmentRec } from "../../../store/appointments/actions";
import { InputLabel, Typography } from "@mui/material";
import { useLocation } from "react-router";
import { useState } from "react";

const AppointmentServices = ({
  serviceType,
  setServiceType,
  error,
  register,
  setValue,
  watch,
}) => {
  const { recomended } = useSelector((state) => state.appointments);
  const location = useLocation();

  const handleChange = (event) => {
    setServiceType(event.target.value);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAppontmentRec());
  }, []);
  useEffect(() => {
    if (location?.state?.reason) {
      const matchingObject = recomended.find(
        (obj) => obj.title === location?.state?.reason
      );
      setValue("appointmentReason", matchingObject._id);
    }
  }, []);
  const selectedValue = watch("appointmentReason");
  return (
    <>
      <Typography fontWeight={"bold"}>
        3. What are you looking to get done
      </Typography>
      <FormControl sx={{ mt: "1em !important" }}>
        <InputLabel id="service-reason">Select Appointment Purpose</InputLabel>
        <Select
          error={error && error.appointmentReason}
          labelId="service-reason"
          id="service-reason-id"
          label="Select Appointment Purpose"
          sx={{ backgroundColor: "white" }}
          // defaultValue={recomended && recomended[recomended?.length - 1]?._id}
          defaultValue={
            location?.state?.reason
              ? recomended && recomended[recomended?.length - 1]?._id
              : ""
          }
          // value={selectedValue}
          {...register("appointmentReason", { required: true })}
        >
          <MenuItem disabled value="">
            <em>Services</em>
          </MenuItem>
          {recomended &&
            recomended.map((item, key) => {
              return (
                <MenuItem key={key} value={item._id}>
                  {item.title}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
    </>
  );
};
export default AppointmentServices;
