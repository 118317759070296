import {
  Button,
  Card,
  CardContent,
  CardHeader,
  createTheme,
  Grid,
  IconButton,
  MenuItem,
  responsiveFontSizes,
  Select,
  Stack,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import Delete from "../../../assets/images/delete.svg";
import RecentlyRecomendedCard from "./RecentlyRecomendedCard";
import ServiceHistoryCard from "./ServiceHistoryCard";
import { useNavigate } from "react-router";
import NotFound from "../../../assets/images/NotFound.svg";
import { useDispatch } from "react-redux";
import { deleteVehicle } from "../../../store/garage/actions";
import ReuseableModal from "../ReuseableModal";

const CarFaxCard = ({ serviceData, selectedCar, serviceType }) => {
  console.log(serviceType,"serviceType")
  const nav = useNavigate();
  const handleRoute = () => {
    if (serviceType === "Service History") {
      nav(`/garage/service-due/${selectedCar[0]._id}`);
    } else if (serviceType === "Recently Recommended") {
      nav("/appointment", {
        state: {
          selectedCar: selectedCar[0]._id,
          pageName: "RECENTLY_RECOMMENDED",
          reason: "Returning for Recently Recommended Service",
        },
      });
    }
  };
  const dispatch = useDispatch();
  const [deleteCar, setDeleteCar] = useState(false);
  const handleOpenDelete = () => setDeleteCar(true);
  const handleCloseDelete = () => {
    setDeleteCar(false);
    const payload = {
      reason: reason,
      otherReason: otherReason,
    };
    dispatch(deleteVehicle(payload, selectedCar[0]?._id));
  };
  const [reason, setReason] = useState("SOLD");
  const [otherReason, setOtherReason] = useState("");
  const handleReason = (e) => {
    setReason(e.target.value);
  };
  let theme = createTheme({
    typography: {
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      fontWeightBold: 700,
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
  });
  theme = responsiveFontSizes(theme, {
    breakpoints: ["sm", "md", "lg"],
    factor: 2,
  });

  const result = serviceData?.reduce((acc, curr) => {
    const authorizedDate = new Date(
      curr.tekmetricRaw.authorizedDate
    ).toLocaleDateString();

    if (!acc[authorizedDate]) {
      acc[authorizedDate] = [];
    }

    acc[authorizedDate].push(curr);

    return acc;
  }, {});
  const serviceHistory = serviceData?.reduce((acc, obj) => {
    obj?.jobs?.forEach((value) => {
      const authorizedDate = new Date(
        value.authorizedDate
      ).toLocaleDateString();
      if (!acc[authorizedDate]) {
        acc[authorizedDate] = [];
      }
      acc[authorizedDate].push(value);
    });

    return acc;
  }, {});

  return (
    <ThemeProvider theme={theme}>
      <Card
        sx={{
          bgcolor: "#FCFCFD",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          overflow: "auto",
        }}
        className="card-padding"
      >
        <CardHeader
          action={
            <IconButton
              aria-label="settings"
              onClick={() => handleOpenDelete()}
            >
              <img src={Delete} alt="" />
            </IconButton>
          }
          sx={{ width: "100%" }}
        />
        <Typography
          variant={"h5"}
          marginTop={{ xs: "0", md: "-1" }}
          color={"text.secondary"}
          fontWeight={600}
        >
          {serviceType}
        </Typography>
        <img
          src={selectedCar[0]?.imageURL}
          alt=""
          className="car-card-image"
          style={{ marginTop: "10px" }}
        />
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Typography
            variant={"h4"}
            fontWeight={700}
            marginBottom={{ xs: "0", md: "-1" }}
          >
            {`${selectedCar[0]?.tekmetricRaw.year} ${selectedCar[0]?.tekmetricRaw.make} ${selectedCar[0]?.tekmetricRaw.model} `}
          </Typography>
          <Button
            sx={{
              display: serviceType === "View Manufacturer" ? "none" : "inherit",
              minWidth: "15em",
              background:
                "linear-gradient(80.67deg, #C42E66 27.97%, #D23852 96.9%)",
              color: "white",
              fontSize: "small",
              p: 2,
              borderRadius: "54.6591px",
            }}
            onClick={() => handleRoute()}
          >
            {serviceType === "Recently Recommended Services"
              ? "Book Appointment Now"
              : serviceType === "Service History"
              ? "View Manufacturer Recommended Services"
              : ""}
          </Button>
          <Grid
            container
            mt="1.5em !important"
            rowSpacing={1}
            paddingY={"1rem"}
          >
            {true ? (
              <>
                {serviceType === "Recently Recommended Services" && (
                  <Stack
                    mt={2}
                    overflow={"scroll"}
                    width="100%"
                    direction={"row"}
                    paddingY={"1rem"}
                    spacing={1}
                  >
                    {serviceData.length !== 0 ? (
                      Object.keys(result).map(function (key, index) {
                        const data = { ...result[key] };
                        return (
                          <Stack padding={".5em"} width="fit-content">
                            <RecentlyRecomendedCard serviceData={data} />
                          </Stack>
                        );
                      })
                    ) : (
                      <Grid
                        item
                        xs={12}
                        textAlign={"center"}
                        justifyContent="center"
                        alignItems="center"
                        fontWeight={"bold"}
                        sx={{ display: "flex", flexDirection: "column" }}
                      >
                        <img src={NotFound} alt="" />
                        {`${
                          serviceType === "Recently Recommended Services"
                            ? "NO SERVICES HAVE BEEN RECENTLY RECOMMENDED"
                            : "THIS VEHICLE HAS NO RECORDED SERVICE HISTORY WITH US"
                        }`}
                      </Grid>
                    )}
                  </Stack>
                )}
                {serviceType === "Service History" &&
                serviceData &&
                serviceData.length !== 0 ? (
                  <Stack flexDirection={"row"}>
                    {Object.keys(serviceHistory)
                      .sort((a, b) => {
                        const dateA = new Date(
                          serviceHistory[a][0]?.authorizedDate
                        );
                        const dateB = new Date(
                          serviceHistory[b][0]?.authorizedDate
                        );
                        return dateB - dateA;
                      })
                      .map(function (key, index) {
                        const data = { ...serviceHistory[key] };
                        return (
                          <ServiceHistoryCard
                            serviceData={data}
                            serviceDate={data[0]?.authorizedDate}
                            serviceType={serviceType}
                          />
                        );
                      })}
                  </Stack>
                ) : (
                  ""
                )}
              </>
            ) : (
              <Grid
                item
                xs={12}
                textAlign={"center"}
                justifyContent="center"
                alignItems="center"
                fontWeight={"bold"}
                sx={{ display: "flex", flexDirection: "column" }}
              >
                <img src={NotFound} alt="" />
                {`${
                  serviceType === "Recently Recommended Services"
                    ? "NO SERVICES HAVE BEEN RECENTLY RECOMMENDED"
                    : "THIS VEHICLE HAS NO RECORDED SERVICE HISTORY WITH US"
                }`}
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
      {/* Delete Car Modal */}
      <ReuseableModal
        handleClose={() => setDeleteCar(false)}
        open={deleteCar}
        width={{ xs: "90%", md: "30%" }}
      >
        <img src={selectedCar[0].imageURL} alt="" width={150} />
        <Typography fontWeight={"bold"}>
          Do you want to delete your vehicle?
        </Typography>
        <Stack spacing={1} width={"100%"}>
          <Select
            labelId="delete-reason"
            id="delete-reason"
            value={reason}
            onChange={(e) => handleReason(e)}
            sx={{ width: "100%", color: "black" }}
          >
            <MenuItem value={"SOLD"}>SOLD VEHICLE</MenuItem>
            <MenuItem value={"NOT IN SERVICE"}>VEHICLE NOT IN SERVICE</MenuItem>
            <MenuItem value={"Other"}>Other(Please Specify)</MenuItem>
          </Select>
          {reason === "Other" ? (
            <TextField
              id="outlined-multiline-static"
              label="Reason to delete vehicle?"
              multiline
              value={otherReason}
              onChange={(e) => setOtherReason(e.target.value)}
              rows={4}
              fullWidth
            />
          ) : (
            ""
          )}
        </Stack>
        <Stack direction={"row"} width={"100%"} spacing={2}>
          <Button
            onClick={() => handleCloseDelete()}
            variant="contained"
            type="submit"
            size="large"
            sx={{
              borderRadius: "54.6591px",
              bgcolor: "#F83D4B",
              fontSize: "small",
              p: 2,
              width: "100%",
            }}
          >
            Yes, Delete
          </Button>
          <Button
            onClick={() => setDeleteCar(false)}
            variant={"contained"}
            sx={{
              borderRadius: "54.6591px",
              background: "#667085",
              fontSize: "small",
              p: 2,
              width: "inherit",
            }}
          >
            No, Cancel
          </Button>
        </Stack>
      </ReuseableModal>
    </ThemeProvider>
  );
};

export default CarFaxCard;
