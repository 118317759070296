import {
  Card,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
import Alert from "../../../assets/images/alert.svg";
import { ConvertDate } from "../ConvertDate";

const RecentlyRecomendedCard = ({ serviceData }) => {
  const Date = ConvertDate(serviceData[0]?.tekmetricRaw?.authorizedDate);

  return (
    <Card
      sx={{
        maxWidth: 302,
        minWidth: 302,
        bgcolor: "#F2F4F7",
        borderRadius: "10px",
        paddingY: "1em",
      }}
    >
      {serviceData && (
        <>
          <Typography px="18px" fontWeight={"bold"}>
            {Date}
          </Typography>
          {Object.keys(serviceData).map(function (key, index) {
            const data = { ...serviceData[key] };
            return (
              <ListItem>
                <ListItemIcon
                  sx={{
                    minWidth: "fit-content",
                    mr: "6px",
                  }}
                >
                  <img src={Alert} alt="" />
                </ListItemIcon>
                <ListItemText primary={data.title} />
              </ListItem>
            );
          })}
        </>
      )}
    </Card>
  );
};

export default RecentlyRecomendedCard;
