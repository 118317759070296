import { Stack } from '@mui/material'
import React from 'react'
import AccountComponent from '../../../components/profile/account/AccountComponent'

const AccountScreen = () => {
    return (
        <Stack width={{ xs: "100vw", md: "75vw" }}>
            <AccountComponent />
        </Stack>
    )
}

export default AccountScreen