import React from 'react'
import RecoverPasswordComponent from '../../components/auth/forgotPassword/recoverPassword/RecoverPasswordComponent'

const RecoverPasswordScreen = () => {
    return (
        <>
            <RecoverPasswordComponent />
        </>
    )
}

export default RecoverPasswordScreen