import axios from "../../axios/AxiosConfig";
import { fork, put, all, takeLatest, select } from "redux-saga/effects";
import { GET_COUPON_DETAIL } from "./actiontypes";
import {
  getCouponDetailFailedAction,
  getCouponDetailSuccessAction,
} from "./actions";

//coupon detail Saga
function* getCouponDetail({ payload }) {
  const userId = yield select((state) => state.auth.user?._id)
  try {
    const response = yield axios.get(`/coupon/${payload.couponCode}`);
    if (response?.data?.data) {
      yield put(
        getCouponDetailSuccessAction({
          data: response.data.data,
          message: response.data.message,
          error: response?.data.error,
          userId
        })
      );
    } else {
      yield put(
        getCouponDetailFailedAction({
          data: null,
          message: response.data.message,
          userId
        })
      );
    }
  } catch (error) {
    yield put(
      getCouponDetailFailedAction({
        data: null,
        message: error.response.data.error,
        error: error.response.data.error,
        userId
      })
    );
  } finally {
  }
}

//coupon detail Watcher
function* watchGetCouponDetail() {
  yield takeLatest(GET_COUPON_DETAIL, getCouponDetail);
}
export default function* couponSaga() {
  yield all([fork(watchGetCouponDetail)]);
}
