import React from "react";
import { IMaskInput } from "react-imask";
import TextField from "@mui/material/TextField";
import { Typography } from "@mui/material";

function GuestPhoneInput({
  label,
  phoneValue,
  setPhoneValue,
  variant,
  register,
  error,
}) {
  // Define the mask as a string
  const mask = "+1 (000) 000-0000";

  // Handle the change event and update the value accordingly
  const handleChange = (event) => {
    setPhoneValue(event.target.value);
  };

  return (
    <>
      <Typography fontWeight={"bold"}>4. What is your mobile number?</Typography>
      <TextField
        error={error && error.phoneNumber}
        label={label}
        value={phoneValue}
        fullWidth
        variant={variant}
        {...register("phoneNumber", {
          required: true,
          max: 17,
          min: 17,
          maxLength: 17,
          pattern: /^\+1\s\(\d{3}\)\s\d{3}-\d{4}$/i,
        })}
        sx={{ backgroundColor: "white", marginTop: "1em !important" }}
        onChange={handleChange}
        InputProps={{
          inputComponent: IMaskInput,
          inputProps: {
            mask: mask,
            definitions: {
              "#": /[0-9]/, // Only allow digits
            },
          },
          inputMode: "numeric", // Show numeric keyboard on mobile
          pattern: "[0-9]*", // Only allow digits
          autoComplete: "tel", // Enable autocomplete for phone numbers
        }}
      />
    </>
  );
}

export default GuestPhoneInput;
