import { Grid, Link, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import NotFound from "../../../assets/images/NotFound.svg";
import BlogCard from "../../reuseable/cards/BlogCard";
import RewardCard from "../../reuseable/cards/RewardCard";
import RefferCard from "../../reuseable/cards/RefferCard";
import CarouselCard from "../../reuseable/carousel/CarouselCard";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getBlogs, getOffers } from "../../../store/dashboard/actions";
import { ClipLoader } from "react-spinners";
import { getAppontment } from "../../../store/appointments/actions";
import { getCars } from "../../../store/garage/actions";

const DashboardComponent = () => {
  const { blogs, isLoading } = useSelector((state) => state.dashboard);
  const nav = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOffers());
    dispatch(getBlogs());
    dispatch(getAppontment());
    dispatch(getCars());
  }, []);

  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };

  return (
    <Stack padding={"3em 2em"} spacing={4}>
      <Stack spacing={4}>
        <Typography variant="h4" color={"#344054"}>
          Dashboard
        </Typography>
        <CarouselCard />
        <Link
          onClick={() => nav("/all-offers")}
          underline="hover"
          alignSelf={"end"}
          sx={{ color: "#F83D4B" }}
        >
          See all Offers
        </Link>
        <Stack
          direction={{ md: "column", lg: "row" }}
          justifyContent={"space-between"}
          spacing={{ md: 2, lg: "0" }}
        >
          <RewardCard />
          <RefferCard />
        </Stack>
      </Stack>
      <Stack spacing={4}>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"baseline"}
        >
          <Typography variant="h4" color={"#344054"}>
            Car Care Tips
          </Typography>
          <Typography
            component={Link}
            color={"#F83D4B"}
            sx={{ textDecoration: "none", cursor: "pointer" }}
            onClick={() => nav("/all-blogs")}
          >
            View All Tips
          </Typography>
        </Stack>
        <ClipLoader
          color={"#D13851"}
          loading={isLoading}
          cssOverride={override}
          size={50}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
        <Grid container xs={12} display={isLoading ? "none" : "inherit"}>
          {blogs?.total > 0 ? (
            blogs?.blogs.slice(0, 3).map((item, key) => {
              return (
                <Grid item xs={12} sm={4} lg={4}>
                  <BlogCard
                    key={key}
                    header={item.title}
                    content={item.description}
                    cardImage={item.imageURL}
                    item={item}
                  />
                </Grid>
              );
            })
          ) : (
            <Stack
              width={"100%"}
              alignItems={"center"}
              display={"flex"}
              justifyContent={"center"}
              flexDirection={"column"}
            >
              <img src={NotFound} alt="" />
              NO RESULT FOUND
            </Stack>
          )}
        </Grid>
      </Stack>
    </Stack>
  );
};

export default DashboardComponent;
