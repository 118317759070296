import produce from "immer";
import { GET_DYNAMIC_KEYS_FAILED, GET_DYNAMIC_KEYS_SUCCESS } from "../auth/actionTypes";
import {
  CUSTOMER_IO_EVENT_FAILED,
  CUSTOMER_IO_EVENT_SUCCESS,
  GET_BLOGS_FAILED,
  GET_BLOGS_SUCCESS,
  GET_NOTIFICATIONS_FAILED,
  GET_NOTIFICATIONS_SUCCESS,
  GET_OFFERS_FAILED,
  GET_OFFERS_SUCCESS,
  READ_ALL_NOTIFICATIONS_FAILED,
  READ_ALL_NOTIFICATIONS_SUCCESS,
  START_LOADING,
  STOP_LOADING,
} from "./actionTypes";

const initialState = {
  offers: null,
  blogs: null,
  notifications: null,
  readAll: "",
  keys: null,
};

const Offers = produce((state, action) => {
  switch (action.type) {
    case START_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case STOP_LOADING:
      return {
        ...state,
        isLoading: false,
      };

    case GET_OFFERS_SUCCESS:
      state.offers = action.payload;
      break;

    case GET_OFFERS_FAILED:
      break;

    case GET_BLOGS_SUCCESS:
      state.blogs = action.payload;
      break;

    case GET_BLOGS_FAILED:
      break;

    case GET_NOTIFICATIONS_SUCCESS:
      state.notifications = action.payload;
      break;

    case GET_NOTIFICATIONS_FAILED:
      break;

    case READ_ALL_NOTIFICATIONS_SUCCESS:
      break;

    case READ_ALL_NOTIFICATIONS_FAILED:
      break;

    case CUSTOMER_IO_EVENT_SUCCESS:
      break;

    case CUSTOMER_IO_EVENT_FAILED:
      break;
    case GET_DYNAMIC_KEYS_SUCCESS:
      state.keys = action.payload;
      break;

    case GET_DYNAMIC_KEYS_FAILED:
      break;

    default:
      break;
  }
}, initialState);

export default Offers;
