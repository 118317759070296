import { TextField, Typography } from "@mui/material";
import React from "react";

const GuestEmailAddress = ({
  emailAddress,
  setEmailAddress,
  register,
  error,
}) => {
  const handleChange = (event) => {
    setEmailAddress(event.target.value);
  };
  return (
    <>
      <Typography fontWeight={"bold"} sx={{ width: "100%" }}>
        6. What is your email address?
      </Typography>
      <TextField
        error={error && error.email}
        sx={{ bgcolor: "white", marginTop: "1em !important" }}
        id="email-address"
        label="Please enter your email address"
        variant="outlined"
        value={emailAddress}
        {...register("email", { required: true })}
        onChange={handleChange}
        type="email"
        fullWidth
        required
      />
    </>
  );
};

export default GuestEmailAddress;
