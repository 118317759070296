import React from "react";
import styled from "@emotion/styled";
import { Badge } from "@mui/material";
function CustomBadges() {
  const CustomBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      backgroundColor: "#D03654",
    },
  }));
  return (
    <>
      <CustomBadge
        variant="dot"
        invisible={false}
        sx={{ right: "15px !important" }}
      />
    </>
  );
}

export default CustomBadges;
