import {
  Avatar,
  Badge,
  Button,
  createTheme,
  Divider,
  Drawer,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import React, { useState } from "react";
import { sideBarLIst, timeSheet } from "../../data/data";
import ReuseableModal from "../reuseable/ReuseableModal";
import "../../utils/css/styles.css";
import { useLocation, useNavigate } from "react-router";
import storage from "redux-persist/lib/storage";
import { useDispatch, useSelector } from "react-redux";
import googlemaps from "../../assets/images/google-maps.svg";
import styled from "@emotion/styled";
import RefferModal from "../reuseable/RefferModal";
import { useEffect } from "react";
import { userFlush } from "../../store/auth/actions";
import CustomBadges from "../reuseable/customBadge";
const CustomButton = styled(Button)(({ theme }) => ({
  "&:hover": {
    backgroundColor: "red", // your hover styles here
  },
}));
const SideBar = () => {
  const token = localStorage.getItem("token");
  const nav = useNavigate();
  const [open, setOpen] = useState(false);
  const [refferModal, setRefferModal] = useState(false);
  const handleCloseRefferModal = () => setRefferModal(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { palette } = createTheme();
  const { augmentColor } = palette;

  const createColor = (mainColor) =>
    augmentColor({ color: { main: mainColor } });
  const theme = createTheme({
    palette: {
      anger: createColor("#f83d4b"),
    },
  });

  const { user } = useSelector((state) => state.auth);
  const { carsList } = useSelector((state) => state.garage);
  const [registerGuest, setRegisterGuest] = useState(false);
  const handleRegisterGuestClose = () => setRegisterGuest(false);
  const location = useLocation();
  const [logout, setLogout] = useState(false);
  const dispatch = useDispatch();
  const handleLogoutClose = () => {
    setLogout(false);
    storage.removeItem("persist:root");
    window.localStorage.clear();
    sessionStorage.clear();
    dispatch(userFlush());
    nav("/login");
  };
  const handlePath = (path) => {
    if (token) {
      if (path === "/login") {
        setLogout(true);
      } else if (path === "modal") {
        setRefferModal(true);
      } else {
        nav(path);
      }
    } else {
      setRegisterGuest(true);
    }
  };
  const newTimeSheet = timeSheet.map((item, index) => {
    return {
      day: item.day,
      startTime: user?.shopId?.openingHours[index]?.from,
      endTime: user?.shopId?.openingHours[index]?.till,
    };
  });
  const [userName, setuserName] = useState(null);
  useEffect(() => {
    setuserName(capitalizeFirstLetter(user?.fullName?.split(" ")[0]));
  }, [userName]);
  const date = new Date(); // replace this with your desired date
  const options = { weekday: "long" };
  const currentHour = date.getHours();
  const dayName = date.toLocaleDateString("en-US", options);
  let count = 0;
  carsList !== null &&
    carsList.forEach((item) => {
      count += item.servicesDueCount;
    });
  const referralMessage = `Hey! I wanted to refer you to Integrity 1st Automotive at (address) they are my trusted shop
    for everything from oil changes to repairs. By booking an appointment with my referral
    link, you get $25.00 at the end of your first service https://app.integrity1auto.com/reff/${user?.referralCode}`;
  function capitalizeFirstLetter(str) {
    return str?.charAt(0).toUpperCase() + str?.slice(1).toLowerCase();
  }

  return (
    <ThemeProvider theme={theme}>
      {user && (
        <Stack
          width={"25vw"}
          sx={{ backgroundColor: "#ffffff" }}
          display={{ xs: "none", md: "inherit" }}
        >
          <Drawer
            sx={{
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                position: "inherit",
                borderRight: "0px",
              },
            }}
            variant="permanent"
            anchor="left"
          >
            <Stack
              alignItems={"center"}
              justifyContent={"center"}
              paddingX={"24px"}
            >
              {/* && userName?.charAt(0) */}
              <Avatar sx={{ bgcolor: "#F83D4B" }}>
                {userName && userName?.charAt(0)}
              </Avatar>
              <Typography
                fontWeight={"600"}
                color={"#475467"}
                textAlign={"center"}
              >
                {token
                  ? currentHour >= 0 && currentHour <= 3
                    ? `Good Night ${userName}!`
                    : currentHour >= 4 && currentHour <= 11
                    ? `Good Morning ${userName}!`
                    : currentHour >= 12 && currentHour <= 16
                    ? `Good Afternoon ${userName}!`
                    : currentHour >= 17 && currentHour <= 24
                    ? `Good Evening ${userName}!`
                    : ``
                  : currentHour >= 0 && currentHour <= 3
                  ? `Good Night!`
                  : currentHour >= 4 && currentHour <= 11
                  ? `Good Morning!`
                  : currentHour >= 12 && currentHour <= 16
                  ? `Good Afternoon!`
                  : currentHour >= 17 && currentHour <= 24
                  ? `Good Evening!`
                  : ``}
              </Typography>
              {token && (
                <Typography
                  textAlign={"center"}
                  onClick={() =>
                    window.open(user?.shopId?.googleMapsLink, "_blank")
                  }
                  sx={{ cursor: "pointer" }}
                >
                  {`${user?.shopId?.address?.fullAddress}`}
                  <img src={googlemaps} alt="" width={"7%"} />
                </Typography>
              )}
              {token && (
                <Link
                  color={"secondary"}
                  component={Button}
                  onClick={() => handleOpen()}
                >
                  View opening hours
                </Link>
              )}
            </Stack>
            <Divider />
            <List>
              {sideBarLIst.map(({ icon, text, path }, index) => (
                <ListItem
                  key={text}
                  sx={{
                    paddingY: "8px",
                    paddingX: "8px",
                    display: text === "Logout" && !token ? "none" : "inherit",
                  }}
                >
                  <ListItemButton
                    sx={{ marginInlineStart: 1, marginInlineEnd: 1 }}
                    onClick={() => handlePath(path)}
                    className={
                      text === "Logout"
                        ? "logoutStyles"
                        : path === location.pathname
                        ? "sidebarStyle"
                        : text === "Book Appointment" && !token
                        ? "sidebarStyle"
                        : "sidebarUnstyle"
                    }
                  >
                    <ListItemIcon
                      sx={{
                        color:
                          text === "Logout"
                            ? "white"
                            : path === location.pathname
                            ? "#f83d4b"
                            : "#98A3B3",
                      }}
                    >
                      {icon}
                    </ListItemIcon>
                    <ListItemText primary={text} />
                    {text === "My Garage" ? <CustomBadges /> : ""}
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Drawer>
          <ReuseableModal handleClose={handleClose} open={open} width={"40%"}>
            <Typography variant="h4" fontWeight={"600"}>
              Opening Hours
            </Typography>
            <MenuList sx={{ width: "100%" }}>
              {newTimeSheet.map(({ day, startTime, endTime }, key) => {
                return (
                  <>
                    <MenuItem
                      key={key}
                      sx={{ width: "100%" }}
                      className={dayName === day ? "selectedTime" : ""}
                    >
                      <ListItemText sx={{ flex: "2 1 auto" }}>
                        {day}
                      </ListItemText>
                      {startTime === "" || endTime === "" ? (
                        <ListItemText
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            flex: "1 1 auto",
                          }}
                        >
                          Close
                        </ListItemText>
                      ) : (
                        <ListItemText
                          sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            flex: "1 1 auto",
                          }}
                        >{`${startTime} - ${endTime}`}</ListItemText>
                      )}
                    </MenuItem>
                    <Divider />
                  </>
                );
              })}
            </MenuList>
          </ReuseableModal>
          {/* Logout Popup */}
          <ReuseableModal
            handleClose={() => setLogout(false)}
            open={logout}
            width={"fit-content"}
          >
            <Typography variant="h6" fontWeight={"bold"}>
              Are you sure, you want to logout?
            </Typography>
            <Stack direction={"row"} width="100%" spacing={2}>
              <CustomButton
                sx={{
                  borderRadius: "54.6591px",
                  bgcolor: "#F83D4B",
                  fontSize: "small",
                  p: 2,
                  color: "white",
                  width: "100%",
                }}
                onClick={() => handleLogoutClose()}
              >
                Yes
              </CustomButton>
              <CustomButton
                sx={{
                  borderRadius: "54.6591px",
                  bgcolor: "#F83D4B",
                  fontSize: "small",
                  p: 2,
                  color: "white",
                  width: "100%",
                }}
                onClick={() => setLogout(false)}
              >
                Cancel
              </CustomButton>
            </Stack>
          </ReuseableModal>
          {/* guest user register modal */}
          <ReuseableModal
            handleClose={handleRegisterGuestClose}
            open={registerGuest}
            width={"30vw"}
          >
            <Typography variant="h5" fontWeight={"bold"} textAlign="center">
              Register Account to get full access
            </Typography>
            <Stack direction={"row"} width="100%" spacing={2}>
              <CustomButton
                sx={{
                  borderRadius: "54.6591px",
                  bgcolor: "#F83D4B",
                  fontSize: "small",
                  p: 2,
                  color: "white",
                  width: "100%",
                }}
                onClick={() => nav("/register")}
              >
                Register
              </CustomButton>
              <CustomButton
                sx={{
                  borderRadius: "54.6591px",
                  bgcolor: "#F83D4B",
                  fontSize: "small",
                  p: 2,
                  color: "white",
                  width: "100%",
                }}
                onClick={() => handleRegisterGuestClose()}
              >
                Cancel
              </CustomButton>
            </Stack>
          </ReuseableModal>
          {/* Reffer Modal */}
          <RefferModal
            handleClose={handleCloseRefferModal}
            open={refferModal}
            referralMessage={referralMessage}
          />
        </Stack>
      )}
    </ThemeProvider>
  );
};

export default SideBar;
