import { Stack } from "@mui/material";
import React, { useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import logoWhite from "../../assets/images/logoWhite.svg";
import { getDynamicKeys } from "../../store/auth/actions";
import AuthCarousel from "../reuseable/carousel/AuthCarousel";

const LeftSection = () => {
  const { keys } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDynamicKeys());
  }, []);
  const applogo = useRef(null);
  applogo.current = useMemo(() => {
    return keys?.find((appLogo) => appLogo.key === "InAppLogo");
  }, [keys]);
  const sliderData = useMemo(() => {
    return keys?.find((appLogo) => appLogo.key === "onBoardings");
  }, [keys]);

  return (
    <Stack
      paddingLeft={3}
      paddingTop={3}
      height={"100%"}
      justifyContent={"space-between"}
      bgcolor={"#FAFAFA"}
      width={"100%"}
    >
      <img
        alt="app logo"
        src={applogo.current && applogo.current.value}
        style={{ width: "6em" }}
      />
      <Stack paddingX={"10%"} justifyContent={"space-around"} height={"90vh"}>
        <AuthCarousel sliderData={sliderData} />
      </Stack>
    </Stack>
  );
};

export default LeftSection;
