export const REGISTER = "register";
export const REGISTER_SUCCESS = "register_success";
export const REGISTER_FAILED = "register_failed";

export const SHOPS = "shops";
export const SHOPS_SUCCESS = "shops_success";

export const LOGIN = "login";
export const LOGIN_SUCCESS = "login_success";
export const LOGIN_FAILED = "login_failed";

export const USER = "user";
export const USER_SUCCESS = "user_success";
export const USER_FAILED = "user_failed";
export const USER_FLUSH = "user_flush";

export const GET_USER = "get_user";
export const GET_USER_SUCCESS = "get_user_success";
export const GET_USER_FAILED = "get_user_failed";

export const VERIFY_PHONE = "verify_phone";
export const VERIFY_PHONE_SUCCESS = "verify_phone_success";
export const VERIFY_PHONE_FAILED = "verify_phone_failed";

export const SIGNUP = "signup";
export const SIGNUP_SUCCESS = "signup_success";
export const SIGNUP_FAILED = "signup_failed";

export const CHANGE_PASSWORD = "change_password";
export const CHANGE_PASSWORD_SUCCESS = "change_password_success";
export const CHANGE_PASSWORD_FAILED = "change_password_failed";

export const RESET_PASSWORD = "reset_password";
export const RESET_PASSWORD_SUCCESS = "reset_password_success";
export const RESET_PASSWORD_FAILED = "reset_password_failed";

export const RESET_PASSWORD_OTP = "reset_password_otp";
export const RESET_PASSWORD_OTP_SUCCESS = "reset_password_otp_success";
export const RESET_PASSWORD_OTP_FAILED = "reset_password_otp_failed";

export const GET_DYNAMIC_KEYS = "get_dynamic_keys";
export const GET_DYNAMIC_KEYS_SUCCESS = "get_dynamic_keys_success";
export const GET_DYNAMIC_KEYS_FAILED = "get_dynamic_keys_failed";

export const STOP_LOADING = "stop_loading";
export const START_LOADING = "start_loading";

export const CLEAR_DATA = "clear_data";
export const CLEAR_DATA_SUCCESS = "clear_data_success";

export const DELETE_USER = "delete_user";
export const DELETE_USER_SUCCESS = "delete_user_success";
export const DELETE_USER_FAILED = "delete_user_failed";
