import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import unread from "../../assets/images/unread.svg";
import { Stack } from "@mui/system";
import Dashboard from "../../assets/images/dashboard.svg";
import Polygon from "../../assets/images/Polygon.svg";
import Garage from "../../assets/images/garage.svg";
import Calender from "../../assets/images/calender.svg";
import Person from "../../assets/images/person.svg";
import RefferVector from "../../assets/images/RefferVector.svg";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CustomBadges from "../reuseable/customBadge";
import {
  Badge,
  createTheme,
  Divider,
  Link,
  ListItemIcon,
  ThemeProvider,
  Toolbar,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  getNotifications,
  readAllNotifications,
} from "../../store/dashboard/actions";
import RefferModal from "../reuseable/RefferModal";
import storage from "redux-persist/lib/storage";
import ReuseableModal from "../reuseable/ReuseableModal";
import styled from "@emotion/styled";
import { userFlush } from "../../store/auth/actions";

const pages = ["Privacy", "Terms and Conditions", "Contact Us", "Blogs"];
const CustomButton = styled(Button)(({ theme }) => ({
  "&:hover": {
    backgroundColor: "red", // your hover styles here
  },
}));
const CustomBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#D03654",
  },
}));
function Header() {
  const token = localStorage.getItem("token");
  const { user } = useSelector((state) => state.auth);
  const { carsList } = useSelector((state) => state.garage);
  const { notifications } = useSelector((state) => state.dashboard);
  const { keys } = useSelector((state) => state.auth);
  const { palette } = createTheme();
  const { augmentColor } = palette;
  const createColor = (mainColor) =>
    augmentColor({ color: { main: mainColor } });
  const theme = createTheme({
    palette: {
      anger: createColor("#F83D4B"),
    },
  });

  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const nav = useNavigate();
  const headerLogo = useRef(null);
  const headerContact = useRef(null);
  useEffect(() => {
    headerLogo.current = keys?.find((appLogo) => appLogo.key === "InAppLogo");
    headerContact.current = keys?.find(
      (appLogo) => appLogo.key === "ContactUs"
    );
  }, [keys]);
  const handleNavRoute = (page) => {
    page === "Contact Us"
      ? window.open(headerContact.current.value, "_blank")
      : page === "Privacy" || page === "Terms and Conditions"
      ? window.open("https://www.integrity1auto.com/privacy-policy", "_blank")
      : nav("/all-blogs");
  };
  const [notification, setNotification] = useState(null);
  const handleOpenNotification = (event) => {
    setNotification(event.currentTarget);
  };
  const handleCloseNotification = () => {
    setNotification(null);
  };
  const ITEM_HEIGHT = 48;
  const [notificatioList, setNotificatioList] = useState([]);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getNotifications());
  }, []);
  const hasNotification = useRef(true);

  useEffect(() => {
    if (notifications && notifications.notifications) {
      hasNotification.current = notifications?.notifications.find(
        (n) => n.isRead === false
      );
      setNotificatioList(notifications.notifications);
    }
  }, [notifications]);

  const dateConvertion = (newDate) => {
    const date = new Date(newDate); // replace this with your desired date
    const options = { weekday: "long" };
    const currentHour = date.getHours();
    const dayName = date.toLocaleDateString("en-US", options);
    return dayName;
  };
  const timeConvertion = (newDate) => {
    const date = new Date(newDate);
    const hour = date.getHours();
    const minutes = date.getMinutes();
    const time = `${hour}:${minutes}`;
    return time;
  };
  const handleReadAllNotifications = () => {
    dispatch(readAllNotifications());
    // setNotification(null);
  };
  const [state, setState] = useState(false);
  const handleOpen = () => setState(true);
  const handleClose = () => setState(false);
  const referralMessage = `Hey! I wanted to refer you to Integrity 1st Automotive at (address) they are my trusted shop
  for everything from oil changes to repairs. By booking an appointment with my referral
  link, you get $25.00 at the end of your first service https://app.integrity1auto.com/reff/${user?.referralCode}`;
  const [logout, setLogout] = useState(false);
  const handleLogoutClose = () => {
    setLogout(false);
    storage.removeItem("persist:root");
    window.localStorage.clear();
    sessionStorage.clear();
    dispatch(userFlush());
    nav("/login");
  };
  const handlePath = (path) => {
    if (path === "/login") {
      setLogout(true);
    } else {
      nav(path);
    }
  };
  let count = 0;
  carsList !== null &&
    carsList.forEach((item) => {
      count += item.servicesDueCount;
    });
  return (
    <ThemeProvider theme={theme}>
      <AppBar
        position="unset"
        elevation={0}
        sx={{ backgroundColor: "#FCFCFD" }}
      >
        <Container
          maxWidth={"30000px"}
          sx={{ height: "100%", width: "100vw", margin: "0px" }}
        >
          <Toolbar disableGutters sx={{ paddingX: 2 }}>
            <Stack sx={{ display: { xs: "flex", md: "flex" }, mr: 1 }}>
              <img
                alt=""
                src={headerLogo.current && headerLogo.current.value}
                style={{ width: "10em", objectFit: "cover" }}
              />
            </Stack>

            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }} />

            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
                justifyContent: "flex-end",
                alignItems: "center",
                gap: "8px",
              }}
            >
              {token &&
                pages.map((page) => (
                  <Button
                    key={page}
                    onClick={() => handleNavRoute(page)}
                    sx={{ my: 2, color: "#475467", display: "block" }}
                  >
                    {page}
                  </Button>
                ))}
              {token && (
                <CustomBadge
                  variant="dot"
                  invisible={Boolean(!hasNotification.current)}
                  sx={{ right: "15px !important" }}
                >
                  <NotificationsOutlinedIcon
                    sx={{ color: "#475467", ml: "8px" }}
                    onClick={handleOpenNotification}
                  />
                </CustomBadge>
              )}
            </Box>
            {token && (
              <>
                <Box sx={{ flexGrow: 0, display: { xs: "flex", md: "none" } }}>
                  <Tooltip title="Open settings">
                    <Badge
                      variant="dot"
                      invisible={Boolean(!hasNotification.current)}
                      color="primary"
                      sx={{ right: "15px !important" }}
                    >
                      <NotificationsOutlinedIcon
                        sx={{ color: "#475467", ml: "8px" }}
                        onClick={handleOpenNotification}
                      />
                    </Badge>
                  </Tooltip>
                  <Menu
                    sx={{ mt: "50px" }}
                    id="menu-appbar"
                    anchorEl={notification}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    // keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    PaperProps={{
                      style: {
                        minHeight: ITEM_HEIGHT * 8.2,
                        width: "30ch",
                        borderRadius: "5px !important",
                        overflow: "hidden",
                        backgroundColor: "#F0F3F8",
                        padding: 0,
                      },
                    }}
                    open={Boolean(notification)}
                    onClose={handleCloseNotification}
                  >
                    <Stack
                      direction={"row"}
                      justifyContent="space-between"
                      paddingX={"16px"}
                    >
                      <Typography variant="caption">
                        {notificatioList.length
                          ? "Notifications"
                          : "No Notification"}
                      </Typography>
                      {notificatioList.length ? (
                        <Typography
                          component={Link}
                          variant="caption"
                          color={"#1A1F36"}
                          sx={{
                            textDecorationColor: "#1A1F36",
                            cursor: "pointer",
                          }}
                          onClick={() => handleReadAllNotifications()}
                        >
                          Read all
                          <CheckCircleOutlineIcon
                            fontSize="sm"
                            sx={{ fill: "#F83D4B !important" }}
                          />
                        </Typography>
                      ) : (
                        ""
                      )}
                    </Stack>
                    <Stack
                      height={`${ITEM_HEIGHT * 8.1}px`}
                      sx={{ overflow: "scroll" }}
                      paddingTop="0.5em"
                    >
                      {notificatioList && notificatioList.length
                        ? notificatioList?.map((item) => {
                            return (
                              <Stack
                                direction={"row"}
                                onClick={() => setNotification(null)}
                                sx={{ backgroundColor: "white" }}
                                spacing={1}
                                paddingX={"16px"}
                                paddingY={"8px"}
                                mb="2px"
                              >
                                <Avatar
                                  sx={{
                                    width: 28,
                                    height: 28,
                                    bgcolor: "#F83D4B",
                                  }}
                                >
                                  {user?.fullName.charAt(0)}
                                </Avatar>
                                <Stack width={"100%"}>
                                  <Stack>
                                    <Typography
                                      color={"#98A2B3"}
                                      variant="body2"
                                      fontWeight={"bold"}
                                    >
                                      {item.title}
                                    </Typography>
                                    <Typography
                                      color={"#98A2B3"}
                                      variant="body2"
                                    >
                                      {item.payload.blogTitle}
                                    </Typography>
                                  </Stack>
                                  <Stack
                                    direction="row"
                                    justifyContent={"space-between"}
                                  >
                                    <Typography variant="caption">
                                      {`${dateConvertion(
                                        item.createdAt
                                      )} at ${timeConvertion(item.createdAt)}`}
                                    </Typography>
                                    {!item?.isRead && (
                                      <img src={unread} alt="" />
                                    )}
                                  </Stack>
                                </Stack>
                              </Stack>
                            );
                          })
                        : null}
                    </Stack>
                  </Menu>
                </Box>

                <Box sx={{ flexGrow: 0 }}>
                  <Tooltip title="Open settings">
                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                      <Avatar sx={{ bgcolor: "#F83D4B" }}>
                        {user && user?.fullName.charAt(0)}
                      </Avatar>
                    </IconButton>
                  </Tooltip>
                  <Menu
                    sx={{ mt: "60px" }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    // keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                  >
                    <Stack paddingX={"1em"}>
                      <MenuItem
                        onClick={() => {
                          setAnchorElUser(null);
                          nav("/");
                        }}
                        sx={{ paddingY: "0.7em" }}
                      >
                        <ListItemIcon color={"#98A2B3"}>
                          <img alt="" src={Dashboard} />
                        </ListItemIcon>
                        <Typography color={"#98A2B3"}>Dashboard</Typography>
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          handleCloseUserMenu();
                          nav("/garage");
                        }}
                        sx={{ paddingY: "0.7em" }}
                      >
                        <ListItemIcon color={"#98A2B3"}>
                          <img alt="" src={Garage} />
                        </ListItemIcon>
                        <Typography color={"#98A2B3"}>My Garage</Typography>
                        <ListItemIcon
                          color={"#98A2B3"}
                          sx={{ justifyContent: "end", width: "50%" }}
                        >
                          {count ? <CustomBadges /> : ""}
                          {/* <Badge badgeContent={count} color="anger"></Badge> */}
                        </ListItemIcon>
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          handleCloseUserMenu();
                          nav("/appointment");
                        }}
                        sx={{ paddingY: "0.7em" }}
                      >
                        <ListItemIcon color={"#98A2B3"}>
                          <img alt="" src={Calender} />
                        </ListItemIcon>
                        <Typography color={"#98A2B3"}>
                          Book Appointment
                        </Typography>
                      </MenuItem>
                      <Divider />
                      <MenuItem
                        onClick={() => {
                          handleCloseUserMenu();
                          nav("/account");
                        }}
                        sx={{ paddingY: "0.7em" }}
                      >
                        <ListItemIcon color={"#98A2B3"}>
                          <img alt="" src={Person} />
                        </ListItemIcon>
                        <Typography color={"#98A2B3"}>Account</Typography>
                      </MenuItem>
                      {token ? (
                        <>
                          <MenuItem
                            onClick={() => {
                              handleCloseUserMenu();
                              handleOpen();
                            }}
                            sx={{ paddingY: "0.7em" }}
                          >
                            <ListItemIcon color={"#98A2B3"}>
                              <img alt="" src={RefferVector} />
                            </ListItemIcon>
                            <Typography color={"#98A2B3"}>
                              Refer & Earn
                            </Typography>
                          </MenuItem>
                          <Button
                            variant="outlined"
                            color="anger"
                            fullWidth
                            sx={{
                              borderRadius: "54.6591px",
                              marginY: "0.7em",
                            }}
                            onClick={() => handlePath("/login")}
                          >
                            Logout
                          </Button>
                        </>
                      ) : (
                        ""
                      )}
                    </Stack>
                  </Menu>
                  {Boolean(anchorElUser) && (
                    <img
                      src={Polygon}
                      alt=""
                      style={{
                        position: "absolute",
                        top: "3.4125em",
                        right: "2.1875em",
                        zIndex: 3000,
                        width: "2%",
                      }}
                    />
                  )}
                </Box>
              </>
            )}
          </Toolbar>
        </Container>
      </AppBar>
      <RefferModal
        handleClose={handleClose}
        open={state}
        referralMessage={referralMessage}
      />
      {/* Logout Popup */}
      <ReuseableModal
        handleClose={() => setLogout(false)}
        open={logout}
        width={"fit-content"}
      >
        <Typography variant="h6" fontWeight={"bold"}>
          Are you sure, you want to logout?
        </Typography>
        <Stack direction={"row"} width="100%" spacing={2}>
          <CustomButton
            sx={{
              borderRadius: "54.6591px",
              bgcolor: "#F83D4B",
              fontSize: "small",
              p: 2,
              color: "white",
              width: "100%",
            }}
            onClick={() => handleLogoutClose()}
          >
            Yes
          </CustomButton>
          <CustomButton
            sx={{
              borderRadius: "54.6591px",
              bgcolor: "#F83D4B",
              fontSize: "small",
              p: 2,
              color: "white",
              width: "100%",
            }}
            onClick={() => setLogout(false)}
          >
            Cancel
          </CustomButton>
        </Stack>
      </ReuseableModal>
    </ThemeProvider>
  );
}
export default Header;
