import produce from "immer";
import { toast } from "react-hot-toast";
import {
  STOP_LOADING,
  LOGIN_SUCCESS,
  REGISTER_SUCCESS,
  START_LOADING,
  SHOPS_SUCCESS,
  LOGIN_FAILED,
  CLEAR_DATA,
  VERIFY_PHONE_SUCCESS,
  VERIFY_PHONE_FAILED,
  GET_DYNAMIC_KEYS_FAILED,
  GET_DYNAMIC_KEYS_SUCCESS,
  REGISTER_FAILED,
  SIGNUP_SUCCESS,
  SIGNUP_FAILED,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILED,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILED,
  RESET_PASSWORD_OTP_SUCCESS,
  RESET_PASSWORD_OTP_FAILED,
  USER_SUCCESS,
  USER_FAILED,
  USER_FLUSH,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILED,
  GET_USER_SUCCESS,
  GET_USER_FAILED,
} from "./actionTypes";

const initialState = {
  user: null,
  token: null,
  isLoading: false,
  errorMessage: null,
  keys: null,
  shops: [],
  authenticationFailedMessage: "",
};

const Auth = produce((state, action) => {
  switch (action.type) {
    case START_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case STOP_LOADING:
      return {
        ...state,
        isLoading: false,
      };
    case CLEAR_DATA:
      state.errorMessage = action.payload;
      break;
    case LOGIN_SUCCESS:
      localStorage.setItem("token", action.payload.token);
      state.user = action.payload.user;
      state.errorMessage = null;
      break;

    case LOGIN_FAILED:
      state.errorMessage = action.payload;
      break;
    case REGISTER_SUCCESS:
      break;
    case REGISTER_FAILED:
      state.errorMessage = action.payload;
      break;

    case SHOPS_SUCCESS:
      state.shops = action.payload;
      break;
    case VERIFY_PHONE_SUCCESS:
      state.token = action.payload.token;
      state.user = action.payload.user;
      // sessionStorage.setItem("signupToken", action.payload.token);
      // localStorage.setItem("token", action.payload.token);
      break;
    case VERIFY_PHONE_FAILED:
      toast.error(action.payload);
      break;

    case CHANGE_PASSWORD_SUCCESS:
      toast.success(action.payload);
      break;

    case CHANGE_PASSWORD_FAILED:
      toast.error(action.payload.data.message);

      break;

    case RESET_PASSWORD_SUCCESS:
      sessionStorage.setItem("resetPhone", action.phone.phoneNumber);
      break;
    case RESET_PASSWORD_FAILED:
      toast.error(action.payload);
      break;

    case RESET_PASSWORD_OTP_SUCCESS:
      localStorage.setItem("token", action.payload.token);
      break;
    case RESET_PASSWORD_OTP_FAILED:
      toast.error(action.payload);
      break;

    case GET_DYNAMIC_KEYS_SUCCESS:
      state.keys = action.payload;
      break;

    case GET_DYNAMIC_KEYS_FAILED:
      break;

    case USER_SUCCESS:
      toast.success("Passwod has been updated successfully");
      break;

    case USER_FAILED:
      toast.error("Request Failed");
      break;

    case GET_USER_SUCCESS:
      state.user = action.payload;
      break;

    case GET_USER_FAILED:
      return {
        ...state,
        authenticationFailedMessage: action?.payload,
      };
    case USER_FLUSH:
      state.user = null;
      break;

    case SIGNUP_SUCCESS:
      state.user = action.payload;
      break;
    case SIGNUP_FAILED:
      toast.error(action.payload);
      break;
    case DELETE_USER_SUCCESS:
      toast.success("deleted");
      state.user = null;
      break;

    case DELETE_USER_FAILED:
      toast.error("Error while deleting user");
      break;

    default:
      break;
  }
}, initialState);

export default Auth;
