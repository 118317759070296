import styled from "@emotion/styled";
import { Avatar, Snackbar, SnackbarContent, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const SuccessSnackbarContent = styled(SnackbarContent)({
  backgroundColor: "#F84F51",
  color: "white",
});

const MessageContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
});

const MessageText = styled(Typography)({
  marginLeft: "1rem",
});

const MessageAvatar = styled(Avatar)({
  marginRight: "1rem",
});

const FirebaseNotification = ({ notification }) => {
  const SnackbarContentComponent = SuccessSnackbarContent;

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(notification)}
      autoHideDuration={5000}
      onClose={() => {}}
    >
      <SnackbarContentComponent
        message={
          <MessageContainer>
            <MessageAvatar
              alt={notification?.title}
              src={notification?.image}
            />
            <MessageText>
              <Typography variant="subtitle1">{notification?.title}</Typography>
              <Typography variant="body1">{notification?.body}</Typography>
            </MessageText>
          </MessageContainer>
        }
      />
    </Snackbar>
  );
};

export default FirebaseNotification;
