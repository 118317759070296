import React from 'react'
import LoginComponent from '../../components/auth/login/LoginComponent'

const LoginScreen = () => {
    return (
        <>
            <LoginComponent />
        </>
    )
}

export default LoginScreen