import { Stack } from "@mui/material";
import React from "react";
import AllAppointmentsComponent from "../../../components/profile/appointment/AllAppointmentsComponent";

const AllAppointmentsScreen = () => {
  return (
    <>
      <Stack width={{ xs: "100vw", md: "75vw" }}>
        <AllAppointmentsComponent />
      </Stack>
    </>
  );
};

export default AllAppointmentsScreen;
