import { Grid, Stack, Typography } from "@mui/material";
import React from "react";
import LeftSection from "../LeftSection";
import RightSection from "../RightSection";
import TelInput from "../../reuseable/TelInput";
import { useNavigate } from "react-router";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { resetPassword } from "../../../store/auth/actions";
import { LoadingButton } from "@mui/lab";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup.object({
  phoneNumber: yup.string().required("Required"),
});

const ForgotPassword = () => {
  const { isLoading, keys } = useSelector((state) => state.auth);
  const nav = useNavigate();

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const dispatch = useDispatch();
  const onSubmit = (data) => {
    let phone = data.phoneNumber;
    phone = `+1${phone.replace(/[^0-9]/g, "")}`;
    const payload = {
      phoneNumber: phone,
    };
    reset();
    sessionStorage.setItem("forgotPhone", phone);
    dispatch(resetPassword(payload, nav));
  };

  return (
    <>
      <Grid container spacing={1} height={"100%"}>
        <Grid item xs="6" display={{ xs: "none", md: "flex" }}>
          <LeftSection />
        </Grid>
        <Grid item xs="12" md="6" display={"flex"} justifyContent={"center"}>
          <RightSection>
            <Typography variant="h4" fontWeight={"bold"} lineHeight={"1.3"}>
              Enter your Phone Number to Recover your Password
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack color={"#848FAC"} spacing={2}>
                <TelInput
                  name="phoneNumber"
                  type="tel"
                  label="Phone Number"
                  className="fullWidthField"
                  control={control}
                  rules={{
                    required: true,
                    max: 14,
                    min: 14,
                    maxLength: 14,
                    pattern: /^\([0-9]{3}\)\s[0-9]{3}\-[0-9]{4}$/i,
                  }}
                  errors={errors}
                />
                <LoadingButton
                  loading={isLoading}
                  variant="contained"
                  type="submit"
                  size="large"
                  fullWidth
                  sx={{
                    borderRadius: "54.6591px",
                    bgcolor: "#F83D4B",
                    fontSize: "small",
                    p: 2,
                  }}
                >
                  Reset my Password
                </LoadingButton>
              </Stack>
            </form>
          </RightSection>
        </Grid>
      </Grid>
    </>
  );
};

export default ForgotPassword;
